import React from 'react'
import { 
  Edit,
  SimpleForm, 
  TextInput, 
  ReferenceInput, 
  BooleanInput, 
  required,
  AutocompleteInput
} from 'react-admin'
import { Grid } from '@mui/material';
import { AxisReferenceInput, MbtisReferenceInput } from '../../../atoms'

const AnswersSkillsEdit = () => (
  <Edit redirect={"show"} undoable={false}>
    <SimpleForm >      
      <Grid container spacing={2} >
      <Grid item xs="auto"  >
      <TextInput source="name" validate={[required()]} />
          </Grid>
          <Grid item xs="auto"  >
          <TextInput source="value" validate={[required()]} />
          </Grid>
          <Grid item xs="auto"  >
          <BooleanInput source="punctuate" />
          </Grid>
          </Grid>
   
      <ReferenceInput 
          source="skill.id"
          reference="skills"
          sort={{ field: 'name', order: 'ASC' }}
          perPage={1000}
        >
          <AutocompleteInput fullWidth 
            source="name"
            optionText="name"
            filterToQuery={(searchText) => ({ name: searchText })}
          />
        </ReferenceInput>      

        <Grid container spacing={2} >
        <Grid item xs="auto"  >
          <AxisReferenceInput source="axis1.id" field="mbti_value_1" />
        </Grid>
        <Grid item xs="auto"  >
          <MbtisReferenceInput source="mbti_value1.id" field="axis1" />
        </Grid>
        <Grid item xs="auto"  >
          <AxisReferenceInput source="axis2.id" field="mbti_value_2" />
        </Grid>
        <Grid item xs="auto"  >
          <MbtisReferenceInput source="mbti_value2.id" field="axis2" />
        </Grid>
        <Grid item xs="auto"  >
          <AxisReferenceInput source="axis3.id" field="mbti_value_3" />
        </Grid>
        <Grid item xs="auto"  >
          <MbtisReferenceInput source="mbti_value3.id" field="axis3" />
        </Grid>
        <Grid item xs="auto"  >
          <AxisReferenceInput source="axis4.id" field="mbti_value_4" />
        </Grid>
        <Grid item xs="auto"  >
          <MbtisReferenceInput source="mbti_value4.id" field="axis4" />
        </Grid>
      </Grid>
    </SimpleForm>
  </Edit>
)

export default AnswersSkillsEdit
