import React from 'react'
import {
    BooleanField,
    Datagrid,
    EmailField,
    Filter,
    List,
    ReferenceField,
    ReferenceInput,
    AutocompleteInput,
    TextField,
    TextInput, EditButton, ShowButton, DeleteButton
} from 'react-admin'

const Filters = props => {
    return <Filter {...props}>
        <TextInput source="id"/>
        <TextInput source="name"/>
        <TextInput source="email"/>
        <ReferenceInput
            source="client.id"
            reference="clients"
            allowEmpty
            label="Cliente"
            pagination={{page: 0, perPage: 1000}}
            sort={{field: 'name', order: 'ASC'}}
            perPage={1000}
        >
            <AutocompleteInput
                source="name"
                optionText="name"
                filterToQuery={(searchText) => ({ name: searchText })} 
            />
        </ReferenceInput>
    </Filter>
}

const UsersList = () => {
    return (
        <List exporter={false} filters={<Filters />}>
            <Datagrid rowClick="show">
                <TextField source="id" />
                <TextField source="name" />
                <TextField source="surname" />
                <TextField source="phone" />

                <BooleanField source="technical_selection_user.enabled" />
                <BooleanField source="rrhh_user.enabled" />
                <BooleanField source="user_feedback.enabled" />
                <BooleanField source="user_exit_interview.enabled" />

                <EmailField source="email" />
                <ReferenceField source="client.id" reference="clients" allowEmpty>
                    <TextField source="name" />
                </ReferenceField>
                <EditButton />
                <ShowButton />
                <DeleteButton />
            </Datagrid>
        </List>
    )
}

export default UsersList;