import React from 'react'
import {Edit, SimpleForm, TextInput, ReferenceInput, SelectInput, NumberInput} from 'react-admin'

const ReportFieldsEdit = () => {
    return (
        <Edit redirect="show">
            <SimpleForm >
                <TextInput source="name" />
                <TextInput source="remote_name" />
                <ReferenceInput source="module_type.id" reference="modules" perPage={100} allowEmpty label="module">
                    <SelectInput optionText="name" />
                </ReferenceInput>
                <ReferenceInput source="client.id" reference="clients" perPage={100}>
                    <SelectInput optionText="name" />
                </ReferenceInput>
                <NumberInput source="order" />
            </SimpleForm>
        </Edit>
    )
}

export default ReportFieldsEdit
