import { MODULE_IDS } from "../../api";

export const retrieveQuestionsDetails = async (params, dataProvider, resourceName) => {
    const id = params.data['id'];
    const response = await dataProvider.getOne(resourceName, { id });
    if (response.data.questions) {
        const questions = response.data.questions.map(q => q.id);
        response.data.questions = questions;

        const questionsDetails = await dataProvider.getList('questions', {
            filter: { id: questions },
            sort: { field: 'id', order: 'DESC' },
            pagination: { page: 1, perPage: 100 },
        });
        response.data.questionsDetails = questionsDetails.data;
    }
    return response;
}

export const retrieveAnswersDetails = async (params, dataProvider, resourceName) => {
    const id = params.data['id'];

    const response = await dataProvider.getOne(resourceName, { id });   
    if (response.data.answers) {
        const answers = response.data.answers.map(q => q.id);
        response.data.answers = answers;

        const answersDetails = await dataProvider.getMany('answers_skills', { ids: answers });
        response.data.answersDetails = answersDetails.data;
    }       
    return response;
}       

export const formatAnswerSkillData = (json) => {
    const formattedData = {
        ...json,
        value: String(json.value),
        'mbti_value_1': json.mbti_value1,
        'mbti_value_2': json.mbti_value2,
        'mbti_value_3': json.mbti_value1,
        'mbti_value_4': json.mbti_value4,
    };
    return formattedData;
};

export const formatQuestions = (data) => {
    if (data.questions && Array.isArray(data.questions)) {
        data.questions = data.questions.map((questionId) => ({ id: questionId }));
    }
    return data;
};

export const formatAnswers = (data) => {
    if (data.answers && Array.isArray(data.answers)) {
        data.answers = data.answers.map((answers) => (data.answers = answers.id));
    }
    return data;
};

export function getIds(element, field) {
    return element[field].map(item => item.id);
}

export function prepareData(json) {
    json.client_has_modules.forEach((el) => {
        el.enabled = true;
        el.inbox_fields = getIds(el, 'inbox_fields');
        json['module_' + el.module_type.id] = el;
    });

    for (const moduleId in MODULE_IDS) {
        if (!json['module_' + MODULE_IDS[moduleId]]) {
            json['module_' + MODULE_IDS[moduleId]] = { enabled: false };
        }
    }

    return json;
}

export function constructVariables(key, value) {
    if (!key || !value) return {};

    return { [key]: value };
}
