import React from "react";
import {
    Admin,
    Resource,
} from "react-admin";
import authProvider from "./providers/authProvider/authProvider";
import dataProvider from "./providers/dataProvider/dataprovider";
import ClientsResource from "./components/pages/clients/index";
import UserResources from "./components/pages/users/index";
import AdminUserResources from "./components/pages/admin_users/index";
import CandidaturesResource from "./components/pages/candidatures";
import i18nProvider from "./providers/i18nProvider/i18n";
import FiltersResource from "./components/pages/filters";
import FiltersBlockResource from "./components/pages/filter_block";
import InboxesResource from "./components/pages/inboxes";
import TagsResource from "./components/pages/tags";
import NotInterestedTag from "./components/pages/not_interested_tags";
import ReportFieldsResources from "./components/pages/report_fields";
// import GeroSessions from "./components/pages/gero_sessions";
import DefaultMessagesResource from "./components/pages/default_messages";
import OnboardingConfigs from "./components/pages/onboarding_configs";
import QuestionsResource from "./components/pages/questions";
import AnswersSkillsResource from "./components/pages/answers_skills";
import AnswersProfilesResource from "./components/pages/answers_profiles";
import ExitInterviewConfigResource from "./components/pages/exit_interview_configs";
import SuggestionFeedbackConfigResource from "./components/pages/suggestion_feedback_configs";
import AvailabilityTag from "./components/pages/availability_tags";
import ProcessResource from "./components/pages/processes";
import Blacklists from "./components/pages/black_list";
import SmsReports from "./components/pages/sms_reports"
import ClientStatistics from "./components/pages/client_statistics";
import Sessions from "./components/pages/sessions";
import RpaReports from "./components/pages/rpa_reports";
import CustomerSuccessPanel from "./components/pages/cs_panel";

function App() {
    return (
        <Admin authProvider={authProvider} dataProvider={dataProvider} i18nProvider={i18nProvider}>
            {/*General */}
            <Resource name="clients" {...ClientsResource} />
            <Resource name="users" {...UserResources} />
            <Resource name="admin_users" {...AdminUserResources} />

            {/*Module Talent Acquisition */}
            <Resource name="candidatures" {...CandidaturesResource} />
            <Resource name="answers_skills" {...AnswersSkillsResource} />
            <Resource name="answers_profile" {...AnswersProfilesResource} />
            <Resource name="availabilities" {...AvailabilityTag} />
            <Resource name="disinteresteds" {...NotInterestedTag} />

            {/*Module Employee Line */}
            <Resource name="processes" {...ProcessResource} />

            {/*Module Feedback */}
            <Resource name="suggestionfeedbackconfigs" {...SuggestionFeedbackConfigResource} />

            {/*Module ExitInterview */}
            <Resource name="exitinterviewconfigs" {...ExitInterviewConfigResource} />

            {/*MultiModule*/}
            <Resource name="questions" {...QuestionsResource} />
            <Resource name="onboardingconfigs" {...OnboardingConfigs} />
            <Resource name="filters" {...FiltersResource} />
            <Resource name="filter_block" {...FiltersBlockResource} />
            <Resource name="reportfields" {...ReportFieldsResources} />
            <Resource name="inboxes" {...InboxesResource} />
            <Resource name="default_messages" {...DefaultMessagesResource} />
            <Resource name="blacklists" {...Blacklists} />
            <Resource name="sms_reports" {...SmsReports} />
            <Resource name="client_statistics" {...ClientStatistics} />
            <Resource name="cs_statistics" {...CustomerSuccessPanel} />
            <Resource name="tags" {...TagsResource} />
            {/* <Resource name="gero_session" {...GeroSessions} /> */}          
            <Resource name="sessions" {...Sessions} />
            <Resource name="rpa_candidates" {...RpaReports} />
        </Admin>
    );
}

export default App;
