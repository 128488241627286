import React from 'react'
import {ReferenceField, Show, Tab, TabbedShowLayout, TextField} from 'react-admin'

const ReportFieldsShow = () => (
    <Show>
        <TabbedShowLayout>
            <Tab label="summary">
                <TextField source="id" />
                <TextField source="name" />
                <TextField source="remote_name" />
                <ReferenceField source="client.id" reference="clients" allowEmpty>
                    <TextField source="name" />
                </ReferenceField>
                <TextField source="order" />
            </Tab>
        </TabbedShowLayout>
    </Show>
)

export default ReportFieldsShow
