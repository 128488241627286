import React from 'react';
import LinearProgress from '@mui/material/LinearProgress';

function determineColor(value) {
    if (value < 50) {
        return "error";
    } else if (value < 80) {
        return "warning";
    } else {
        return "success";
    }
}

function LinearProgressComponent({ value }) {
    return (
        <LinearProgress
            variant="determinate"
            value={Number(value)}
            color={determineColor(Number(value))}
        />
    );
}

export default LinearProgressComponent;