import React from 'react'
import {
  FormDataConsumer,
  Create,
  SimpleForm,
  TextInput,
  NumberInput,
  ReferenceInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  required,
  AutocompleteInput
} from 'react-admin'
import { MODULE_IDS } from "../../../../config/api";
import { Grid } from '@mui/material';
import Box from '@mui/material/Box';

const CandidaturesCreate = () => {

  return (
    <Create redirect={"show"} >
      <SimpleForm>
        <TextInput source="name" validate={[required()]} />
        <TextInput source="remote_name" validate={[required()]} label="Nombre interno" />
        <Box sx={{ width: 400, maxWidth: '100%' }}>
          <ReferenceInput
            fullWidth
            source="client.id"
            reference="clients"
            sort={{ field: 'name', order: 'ASC' }}
            perPage={1000}
          >
            <AutocompleteInput
              source="name"
              optionText="name"
              filterToQuery={(searchText) => ({ name: searchText })}
            />
          </ReferenceInput>
        </Box>
        <Grid container spacing={0.5} >
          <Grid item xs="auto"  >
            <Box sx={{ width: 400, maxWidth: '100%' }}>
              <ReferenceInput
                fullWidth
                source="skill1.id"
                reference="skills"
                sort={{ field: 'name', order: 'ASC' }}
                perPage={1000}
                validate={[required()]}
              >
                <AutocompleteInput
                  source="name"
                  optionText="name"
                  label="Skill 1"
                  filterToQuery={(searchText) => ({ name: searchText })}
                />
              </ReferenceInput>
            </Box>
          </Grid>
          <Grid item xs="auto"  >
            <NumberInput source="weight_skill1" validate={[required()]} />
          </Grid>
        </Grid>

        <Grid container spacing={0.5}>
          <Grid item xs="auto">
            <Box sx={{ width: 400, maxWidth: '100%' }}>
              <ReferenceInput
                fullWidth
                source="skill2.id"
                reference="skills"
                sort={{ field: 'name', order: 'ASC' }}
                perPage={1000}
                validate={[required()]}
              >
                <AutocompleteInput
                  source="name"
                  optionText="name"
                  label="Skill 2"
                  filterToQuery={(searchText) => ({ name: searchText })}
                />
              </ReferenceInput>
            </Box>
          </Grid>
          <Grid item xs="auto">
            <NumberInput source="weight_skill2" validate={[required()]} />
          </Grid>
        </Grid>

        <Grid container spacing={0.5}>
          <Grid item xs="auto">
            <Box sx={{ width: 400, maxWidth: '100%' }}>
              <ReferenceInput
                fullWidth
                source="skill3.id"
                reference="skills"
                sort={{ field: 'name', order: 'ASC' }}
                perPage={1000}
                validate={[required()]}
              >
                <AutocompleteInput
                  source="name"
                  optionText="name"
                  label="Skill 3"
                  filterToQuery={(searchText) => ({ name: searchText })}
                />
              </ReferenceInput>
            </Box>

          </Grid>
          <Grid item xs="auto">
            <NumberInput source="weight_skill3" validate={[required()]} />
          </Grid>
        </Grid>

        <Grid container spacing={0.5}>
          <Grid item xs="auto">
            <Box sx={{ width: 400, maxWidth: '100%' }}>
              <ReferenceInput
                fullWidth
                source="skill4.id"
                reference="skills"
                sort={{ field: 'name', order: 'ASC' }}
                perPage={1000}
                validate={[required()]}
              >
                <AutocompleteInput
                  source="name"
                  optionText="name"
                  label="Skill 4"
                  filterToQuery={(searchText) => ({ name: searchText })}
                />
              </ReferenceInput>
            </Box>
          </Grid>
          <Grid item xs="auto">
            <NumberInput source="weight_skill4" label="Peso Skill 4" validate={[required()]} />
          </Grid>
        </Grid>

        <FormDataConsumer>
          {// eslint-disable-next-line no-unused-vars 
            ({ formData, dispatch, ...rest }) => (
              <ReferenceArrayInput
                {...rest}
                reference="questions"
                source="questions"
                filter={{ "moduleType.id": MODULE_IDS.ID_TALENT_ACQUISITION }}
                perPage={1000}
                allowEmpty
              >
                <AutocompleteArrayInput
                  optionText="internal_name"
                  filterToQuery={searchText => ({ internal_name: searchText })}
                  suggestionLimit={20}
                  fullWidth
                />

              </ReferenceArrayInput>
            )}
        </FormDataConsumer>

        <Grid container spacing={0.5}>
          <Grid item xs="auto">
            <NumberInput source="minimum_percentage_suitable_overwrite" />
          </Grid>
          <Grid item xs="auto">
            <NumberInput source="skill_weight_overwrite" />
          </Grid>
          <Grid item xs="auto">
            <NumberInput source="profile_weight_overwrite" />
          </Grid>
        </Grid>

        <TextInput
          fullWidth
          multiline
          source='sms_start_bot_template'
          inputProps={{ maxLength: 140 }}
          validate={[required()]}
          label="resources.clients.fields.module_talent_acquisition.sms_start_bot_template_helper"
        />

        <TextInput
          fullWidth
          validate={[required()]}
          source='bot_url'
        />

        <TextInput
          fullWidth
          source='schedules_available_to_notify_bot_starter'
          label="resources.clients.fields.module_talent_acquisition.sms_start_bot_template_helper"
        />

      </SimpleForm>
    </Create>
  )
}

export default CandidaturesCreate;


