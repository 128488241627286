import React from 'react';
import {
    Edit,
    SimpleForm,
    TextInput,
    NumberInput,
    BooleanInput,
    SelectInput,
    FormDataConsumer,
    useGetOne,
    Loading,
    Error,
    required,
    email,
    minLength
} from 'react-admin';
import {UserRoles} from '../common.js'
import {useParams} from "react-router-dom";

const ClientEdit = (props) => {
    const { id } = useParams();
    const { data, isLoading, error } = useGetOne('admin_users', { id },{staleTime: 60000});

    if (isLoading) return <Loading />;
    if (error) return <Error />;

    return (
    <Edit {...props} redirect={"show"}>
            <SimpleForm >
                <TextInput source="name" validate={[required()]} />
                <TextInput source="surname" validate={[required()]} />
                <TextInput source="email" validate={[required(), email()]}  />
                <TextInput source="password" type="password" validate={[minLength(8)]}/>
                <BooleanInput source="force_reset_password" />
                <FormDataConsumer>
                    {// eslint-disable-next-line no-unused-vars
                        ({ formData }) => (
                            <>
                                <div className="child-items">
                                    <SelectInput source="admin_user.role" validate={[required()]} choices={UserRoles} />
                                </div>
                            </>
                        )}
                </FormDataConsumer>
            </SimpleForm>
        </Edit>
    );
};

export default ClientEdit;
