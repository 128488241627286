import React, { useState } from 'react'
import {
  AutocompleteArrayInput,
  Create,
  FormDataConsumer,
  ReferenceArrayInput,
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
  AutocompleteInput,
} from 'react-admin'
import { MODULE_IDS, PROCESS_TYPES } from "../../../../config/api";

const ProcessesCreate = () => {
  const [count, setCount] = useState(0)
  return (
    <Create redirect="show" >
      <SimpleForm >
        <TextInput source="name" validate={[required()]} />
        <TextInput source="remote_name" validate={[required()]} />
        <TextInput source="prefix" inputProps={{ maxLength: 3 }} validate={[required()]} />

        <ReferenceInput
          source="client.id"
          reference="clients"
          perPage={1000}
          sort={{ field: 'name', order: 'ASC' }} >
          <AutocompleteInput
            optionText="name"
            filterToQuery={(searchText) => ({ name: searchText })}
          />
        </ReferenceInput>

        <ReferenceInput source="type.id" reference="processtypes" label="processType">
          <SelectInput optionText="name" />
        </ReferenceInput>

        <FormDataConsumer>
          {// eslint-disable-next-line no-unused-vars
            ({ formData, dispatch, ...rest }) => (
              formData.type && formData.type.id === PROCESS_TYPES.ID_TRANSACCIONAL &&
              <ReferenceArrayInput
                {...rest}
                key={`questions_${count}`}
                reference="questions"
                source="questions"
                filter={{ "moduleType.id": MODULE_IDS.ID_EMPLOYEE_LINE }}
                perPage={1000}
                onChange={() => setTimeout(() => setCount(count + 1), 250)}
                allowEmpty
                sort={{ field: 'internal_name', order: 'ASC' }}
              >
                <AutocompleteArrayInput
                  optionText="internal_name"
                  filterToQuery={(searchText) => ({ internal_name: searchText })} />
              </ReferenceArrayInput>
            )}
        </FormDataConsumer>
      </SimpleForm>
    </Create>
  )
}

export default ProcessesCreate
