import React from 'react'
import { Edit, SimpleForm, TextInput, ReferenceInput, SelectInput, NumberInput } from 'react-admin'

const AvailabilityTagsEdit = () => (
  <Edit redirect="show" >
    <SimpleForm >
      <TextInput source="label" />
      <NumberInput source="position" />
      <ReferenceInput source="client.id" reference="clients" perPage={1000}>
        <SelectInput optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
)

export default AvailabilityTagsEdit
