import React, { useState } from 'react';
import {
    Edit,
    SimpleForm,
    TextInput,
    BooleanInput,
    SelectInput,
    ImageInput,
    ImageField,
    FormDataConsumer,
    NumberInput,
    AutocompleteArrayInput,
    regex,
    ReferenceArrayInput,
    required,
} from 'react-admin';
import { ColorInput } from "react-admin-color-picker";
import { MODULE_IDS } from "../../../../config/api";
import { useParams } from "react-router-dom";
import LogoField from "../../../atoms/logo-field/index";
import { WhatsAppSenderTestCallback } from "../common/whatsapp";
import { Button, Snackbar } from "@mui/material";
import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import { ClientFeatures, Languages } from '../common';


const ClientEdit = (props) => {

    const { id } = useParams();
    const [count, setCount] = useState(0);
    const [submitIsDisabled, setSubmitIsDisabled] = useState(false);
    const [whatsappTestSnackbar, setWhatsappTestSnackbar] = useState({});
    const whatsAppPhoneFieldId = 'module_' + MODULE_IDS.ID_TALENT_ACQUISITION + '.whatsapp_test_phone';

    const sendWhatsAppTest = async () => {
        setSubmitIsDisabled(true);
        let lang = document.getElementById('language').value;
        let whatsappSenderId = document.getElementById('module_' + MODULE_IDS.ID_TALENT_ACQUISITION + '.whatsapp_sender_id').value;
        let wabaKey = document.getElementById('module_' + MODULE_IDS.ID_TALENT_ACQUISITION + '.waba_key').value;
        let whatsappTemplate = document.getElementById('module_' + MODULE_IDS.ID_TALENT_ACQUISITION + '.whatsapp_template').value;
        let whatsappTo = document.getElementById(whatsAppPhoneFieldId).value;

        const launchTest = await WhatsAppSenderTestCallback(() => {
            return {
                whatsappSenderId: whatsappSenderId,
                wabaKey: wabaKey,
                whatsappTemplate: whatsappTemplate,
                whatsappTo: whatsappTo,
                lang: lang
            };
        });
        if (launchTest) {
            setSubmitIsDisabled(false);
            setWhatsappTestSnackbar({ message: 'WhatsApp test successful', type: 'success' });
        } else {
            setWhatsappTestSnackbar({ message: 'whatsapp.test.error', type: 'error' });
        }
        setTimeout(() => {
            setWhatsappTestSnackbar({});
        }, 3000);
    }

    return (
        <Edit {...props} redirect={"show"}>
            <SimpleForm redirect="show">
                <Grid container spacing={2} >
                    <Grid item xs="auto" >
                        <Box sx={{ width: 500, maxWidth: '100%' }}>
                            <TextInput fullWidth source="name" validate={[required()]} />
                        </Box>
                    </Grid>
                    <Grid item xs="auto" >
                        <Box sx={{ width: 500, maxWidth: '100%' }}>
                            <TextInput fullWidth source="email" validate={[required()]} />
                        </Box>
                    </Grid>
                    <Grid item xs="auto" >
                        <Box sx={{ width: 500, maxWidth: '100%' }}>
                            <TextInput fullWidth source="email_from" validate={[required()]} />
                        </Box>
                    </Grid>
                </Grid>

                <ColorInput source="text_color_principal" validate={[required()]} />
                <ColorInput source="text_color_secondary" validate={[required()]} />
                <ColorInput source="background_color" validate={[required()]} />

                <LogoField record={{ id }} source="logo" label="resources.clients.fields.currentLogo" />

                <ImageInput source="imageBase64" accept="image/*">
                    <ImageField source="src" title="title" multiple={false} />
                </ImageInput>

                <SelectInput
                    source="lang"
                    choices={Languages}
                    inputProps={{ id: 'language' }}
                />
                <FormDataConsumer>
                    {({ formData, ...rest }) => {
                        const smsapphandleChange = (e) => {
                            formData.sms_available = e.target.checked;
                        }

                        const wathsapphandleChange = (e) => {
                            formData.whatsapp_available = e.target.checked;
                        }

                        return (
                            <>
                                <BooleanInput source="sms_available" onChange={smsapphandleChange} />
                                {formData.sms_available && <TextInput source="sms_prefix" validate={regex(/^\+.+/)} />}
                                <BooleanInput source="whatsapp_available" onChange={wathsapphandleChange} />
                                {formData.whatsapp_available && <NumberInput source="whatsapp_voucher" label="voucher" />}
                            </>
                        )
                    }}
                </FormDataConsumer>
                <TextInput source="sendgrid_token" />
                <TextInput source="data_retention_months" />
                <AutocompleteArrayInput source="features" choices={ClientFeatures} />

                <NumberInput source="max_users" validate={[required()]} defaultValue={50}/>

                <Separator />

                <BooleanInput source={'module_' + MODULE_IDS.ID_TALENT_ACQUISITION + '.enabled'} />
                <FormDataConsumer>
                    {({ formData, ...rest }) => {
                        return (
                            formData['module_' + MODULE_IDS.ID_TALENT_ACQUISITION] &&
                            formData['module_' + MODULE_IDS.ID_TALENT_ACQUISITION].enabled && (
                                <div className="child-items">
                                    <ReferenceArrayInput
                                        {...rest}
                                        reference="inboxes"
                                        source={'module_' + MODULE_IDS.ID_TALENT_ACQUISITION + '.inbox_fields'}
                                        perPage={1000}
                                        key={`inboxes_${count}`}
                                        filter={{ 'moduleType.id': MODULE_IDS.ID_TALENT_ACQUISITION }}
                                        onChange={() => setTimeout(() => setCount(count + 1), 250)}
                                        sort={{ field: 'name', order: 'ASC' }}
                                    >
                                        <AutocompleteArrayInput optionText="name" filterToQuery={(searchText) => ({ name: searchText })} />
                                    </ReferenceArrayInput>

                                    <Grid container spacing={2} >
                                        <Grid item xs="auto" >
                                            <NumberInput {...rest} source={'module_' + MODULE_IDS.ID_TALENT_ACQUISITION + '.minimum_percentage_suitable'} validate={[required()]} />
                                        </Grid>
                                        <Grid item xs="auto" >
                                            <NumberInput {...rest} source={'module_' + MODULE_IDS.ID_TALENT_ACQUISITION + '.skill_weight'} validate={[required()]} />
                                        </Grid>
                                        <Grid item xs="auto" >
                                            <NumberInput {...rest} source={'module_' + MODULE_IDS.ID_TALENT_ACQUISITION + '.profile_weight'} validate={[required()]} />
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={2} >
                                        <Grid item xs="auto" >
                                            <Box sx={{ width: 500, maxWidth: '100%' }}>
                                                <TextInput fullWidth  {...rest} source={'module_' + MODULE_IDS.ID_TALENT_ACQUISITION + '.token_sendgrid_email_bot_starter_template'} />
                                            </Box>
                                        </Grid>
                                        <Grid item xs="auto"  >
                                            <Box sx={{ width: 500, maxWidth: '100%' }}>
                                                <TextInput fullWidth {...rest} source={'module_' + MODULE_IDS.ID_TALENT_ACQUISITION + '.token_sendgrid_email_bot_starter_repush_template'} />
                                            </Box>
                                        </Grid>
                                        <Grid item xs="auto"  >
                                            <Box sx={{ width: 500, maxWidth: '100%' }}>
                                                <TextInput fullWidth {...rest} source={'module_' + MODULE_IDS.ID_TALENT_ACQUISITION + '.token_sendgrid_email_bot_starter_recurrent_template'} />
                                            </Box>
                                        </Grid>

                                    </Grid >

                                    <Grid container spacing={2} >
                                        <Grid item xs="auto"  >
                                            <Box sx={{ width: 500, maxWidth: '100%' }}>
                                                <TextInput fullWidth {...rest} source={'module_' + MODULE_IDS.ID_TALENT_ACQUISITION + '.token_sendgrid_email_bot_feedback_template'} />
                                            </Box>
                                        </Grid>
                                        <Grid item xs="auto" >
                                            <Box sx={{ width: 500, maxWidth: '100%' }}>
                                                <TextInput fullWidth {...rest} source={'module_' + MODULE_IDS.ID_TALENT_ACQUISITION + '.token_sendgrid_chat_template'} />
                                            </Box>
                                        </Grid>
                                        <Grid item xs="auto"  >
                                            <Box sx={{ width: 500, maxWidth: '100%' }}>
                                                <TextInput fullWidth {...rest} source={'module_' + MODULE_IDS.ID_TALENT_ACQUISITION + '.token_sendgrid_citation_template'} />
                                            </Box>
                                        </Grid>

                                    </Grid >

                                    <Grid container spacing={2} >
                                        <Grid item xs="auto"  >
                                            <Box sx={{ width: 500, maxWidth: '100%' }}>
                                                <TextInput fullWidth {...rest} source={'module_' + MODULE_IDS.ID_TALENT_ACQUISITION + '.token_sendgrid_email_notice_template'} label="resources.clients.fields.module_talent_acquisition.token_sendgrid_email_notice_template" />
                                            </Box>
                                        </Grid>
                                        <Grid item xs="auto"  >
                                            <Box sx={{ width: 500, maxWidth: '100%' }}>
                                                <TextInput fullWidth {...rest} source={'module_' + MODULE_IDS.ID_TALENT_ACQUISITION + '.stats_filters_available'} label="resources.clients.fields.stats_filters_available" />
                                            </Box>
                                        </Grid>
                                        <Grid item xs="auto"  >
                                            <Box sx={{ width: 500, maxWidth: '100%' }}>
                                                <TextInput fullWidth
                                                    source={'module_' + MODULE_IDS.ID_TALENT_ACQUISITION + '.schedules_available_to_notify_bot_starter'}
                                                    label="resources.clients.fields.module_talent_acquisition.schedules_available_to_notify_bot_starter"
                                                />
                                            </Box>
                                        </Grid>
                                    </Grid >

                                    <TextInput
                                        {...rest}
                                        fullWidth
                                        multiline
                                        label="resources.clients.fields.module_talent_acquisition.sms_start_bot_template"
                                        source={'module_' + MODULE_IDS.ID_TALENT_ACQUISITION + '.sms_start_bot_template'}
                                        inputProps={{ maxLength: 140 }}
                                    />

                                    <TextInput
                                        {...rest}
                                        fullWidth
                                        multiline
                                        label="resources.clients.fields.module_talent_acquisition.sms_repush_bot_template"
                                        source={'module_' + MODULE_IDS.ID_TALENT_ACQUISITION + '.sms_repush_bot_template'}
                                        inputProps={{ maxLength: 140 }}
                                    />

                                    <TextInput
                                        {...rest}
                                        fullWidth
                                        multiline
                                        label="resources.clients.fields.module_talent_acquisition.sms_bot_starter_recurrent_template"
                                        source={'module_' + MODULE_IDS.ID_TALENT_ACQUISITION + '.sms_bot_starter_recurrent_template'}
                                        inputProps={{ maxLength: 140 }}
                                    />

                                    <TextInput
                                        {...rest}
                                        fullWidth
                                        multiline
                                        label="resources.clients.fields.module_talent_acquisition.sms_feedback_bot_template"
                                        source={'module_' + MODULE_IDS.ID_TALENT_ACQUISITION + '.sms_feedback_bot_template'}
                                        inputProps={{ maxLength: 140 }}
                                    />

                                    <Grid container spacing={2} >
                                        <Grid item xs="auto"  >
                                            <Box sx={{ width: 500, maxWidth: '100%' }}>
                                                <NumberInput fullWidth {...rest} source={'module_' + MODULE_IDS.ID_TALENT_ACQUISITION + '.update_candidature_in_days'} />
                                            </Box>
                                        </Grid>
                                        <Grid item xs="auto"  >
                                            <Box sx={{ width: 500, maxWidth: '100%' }}>
                                                <TextInput fullWidth {...rest} source={'module_' + MODULE_IDS.ID_TALENT_ACQUISITION + '.update_candidature_bot_url'} validate={[required(), regex(/(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/)]} />
                                            </Box>
                                        </Grid>
                                    </Grid >
                                    <BooleanInput {...rest} source={'module_' + MODULE_IDS.ID_TALENT_ACQUISITION + '.talent_clue_integrated'} />
                                </div>
                            )
                        )
                    }}
                </FormDataConsumer>

                <Separator />

                <BooleanInput source={'module_' + MODULE_IDS.ID_EMPLOYEE_LINE + '.enabled'} />
                <FormDataConsumer>
                    {({ formData, ...rest }) => {
                        return (
                            formData['module_' + MODULE_IDS.ID_EMPLOYEE_LINE] &&
                            formData['module_' + MODULE_IDS.ID_EMPLOYEE_LINE].enabled && (
                                <div className="child-items">
                                    <ReferenceArrayInput
                                        {...rest}
                                        reference="inboxes"
                                        source={'module_' + MODULE_IDS.ID_EMPLOYEE_LINE + '.inbox_fields'}
                                        perPage={100}
                                        filter={{ 'moduleType.id': MODULE_IDS.ID_EMPLOYEE_LINE }}
                                        onChange={() => setTimeout(() => setCount(count + 1), 250)}
                                        sort={{ field: 'name', order: 'ASC' }}
                                    >
                                        <AutocompleteArrayInput optionText="name" filterToQuery={(searchText) => ({ name: searchText })} />
                                    </ReferenceArrayInput>

                                    <Grid container spacing={2} >
                                        <Grid item xs="auto"  >
                                            <Box sx={{ width: 500, maxWidth: '100%' }}>
                                                <TextInput fullWidth {...rest} source={'module_' + MODULE_IDS.ID_EMPLOYEE_LINE + '.sendgrid_process_new_template'} />
                                            </Box>
                                        </Grid>
                                        <Grid item xs="auto"  >
                                            <Box sx={{ width: 500, maxWidth: '100%' }}>
                                                <TextInput fullWidth {...rest} source={'module_' + MODULE_IDS.ID_EMPLOYEE_LINE + '.sendgrid_process_status_changed_template'} />
                                            </Box>
                                        </Grid>
                                        <Grid item xs="auto"  >
                                            <Box sx={{ width: 500, maxWidth: '100%' }}>
                                                <TextInput fullWidth {...rest} source={'module_' + MODULE_IDS.ID_EMPLOYEE_LINE + '.stats_filters_available'} label="resources.clients.fields.stats_filters_available" />
                                            </Box>
                                        </Grid>
                                    </Grid >
                                </div>
                            )
                        )
                    }}
                </FormDataConsumer>

                <Separator />

                <BooleanInput source={'module_' + MODULE_IDS.ID_ON_BOARDING + '.enabled'} />
                <FormDataConsumer>
                    {({ formData, ...rest }) => {
                        return (
                            formData['module_' + MODULE_IDS.ID_ON_BOARDING] &&
                            formData['module_' + MODULE_IDS.ID_ON_BOARDING].enabled && (
                                <div className="child-items">
                                    <ReferenceArrayInput
                                        {...rest}
                                        reference="inboxes"
                                        key={`inboxes_${count}`}
                                        source={'module_' + MODULE_IDS.ID_ON_BOARDING + '.inbox_fields'}
                                        perPage={100}
                                        filter={{ 'moduleType.id': MODULE_IDS.ID_ON_BOARDING }}
                                        onChange={() => setTimeout(() => setCount(count + 1), 250)}
                                        sort={{ field: 'name', order: 'ASC' }}
                                    >
                                        <AutocompleteArrayInput
                                            optionText="name"
                                            filterToQuery={(searchText) => ({ name: searchText })}
                                        />
                                    </ReferenceArrayInput>

                                    <Grid container spacing={2} >
                                        <Grid item xs="auto"  >
                                            <Box sx={{ width: 500, maxWidth: '100%' }}>
                                                <TextInput fullWidth {...rest} source={'module_' + MODULE_IDS.ID_ON_BOARDING + '.sendgrid_process_new_template'} />
                                            </Box>
                                        </Grid>
                                        <Grid item xs="auto"  >
                                            <Box sx={{ width: 500, maxWidth: '100%' }}>
                                                <TextInput fullWidth {...rest} source={'module_' + MODULE_IDS.ID_ON_BOARDING + '.sendgrid_process_status_changed_template'} />
                                            </Box>
                                        </Grid>
                                        <Grid item xs="auto"  >
                                            <Box sx={{ width: 500, maxWidth: '100%' }}>
                                                <TextInput fullWidth {...rest} source={'module_' + MODULE_IDS.ID_ON_BOARDING + '.stats_filters_available'} label="resources.clients.fields.stats_filters_available" />
                                            </Box>
                                        </Grid>
                                    </Grid >
                                </div>
                            )
                        )
                    }}
                </FormDataConsumer>

                <Separator />

                <BooleanInput source={'module_' + MODULE_IDS.ID_FEEDBACK + '.enabled'} />
                <FormDataConsumer>
                    {({ formData, ...rest }) => {
                        return (
                            formData['module_' + MODULE_IDS.ID_FEEDBACK] &&
                            formData['module_' + MODULE_IDS.ID_FEEDBACK].enabled && (
                                <div className="child-items">
                                    <ReferenceArrayInput
                                        {...rest}
                                        source={'module_' + MODULE_IDS.ID_FEEDBACK + '.inbox_fields'}
                                        reference="inboxes"
                                        perPage={100}
                                        key={`inboxes_${count}`}
                                        filter={{ 'moduleType.id': MODULE_IDS.ID_FEEDBACK }}
                                        onChange={() => setTimeout(() => setCount(count + 1), 250)}
                                        sort={{ field: 'name', order: 'ASC' }}
                                    >
                                        <AutocompleteArrayInput
                                            optionText="name"
                                            filterToQuery={(searchText) => ({ name: searchText })}
                                        />
                                    </ReferenceArrayInput>

                                    <Grid container spacing={2} >
                                        <Grid item xs="auto"  >
                                            <Box sx={{ width: 500, maxWidth: '100%' }}>
                                                <TextInput fullWidth {...rest} source={'module_' + MODULE_IDS.ID_FEEDBACK + '.sendgrid_feedback_new_template'} />
                                            </Box>
                                        </Grid>
                                        <Grid item xs="auto"  >
                                            <Box sx={{ width: 500, maxWidth: '100%' }}>
                                                <TextInput fullWidth {...rest} source={'module_' + MODULE_IDS.ID_FEEDBACK + '.stats_filters_available'} label="resources.clients.fields.stats_filters_available" />
                                            </Box>
                                        </Grid>
                                    </Grid >
                                </div>
                            )
                        )
                    }}
                </FormDataConsumer>

                <BooleanInput source={'module_' + MODULE_IDS.ID_EXIT_INTERVIEW + '.enabled'} />
                <FormDataConsumer>
                    {({ formData, ...rest }) => {
                        return (
                            formData['module_' + MODULE_IDS.ID_EXIT_INTERVIEW] &&
                            formData['module_' + MODULE_IDS.ID_EXIT_INTERVIEW].enabled && (
                                <div className="child-items">
                                    <ReferenceArrayInput
                                        {...rest}
                                        reference="inboxes"
                                        source={'module_' + MODULE_IDS.ID_EXIT_INTERVIEW + '.inbox_fields'}
                                        perPage={100}
                                        key={`inboxes_${count}`}
                                        sort={{ field: 'name', order: 'ASC' }}
                                        filter={{ 'moduleType.id': MODULE_IDS.ID_EXIT_INTERVIEW }}
                                        onChange={() => setTimeout(() => setCount(count + 1), 250)}
                                    >
                                        <AutocompleteArrayInput
                                            optionText="name"
                                            filterToQuery={(searchText) => ({ name: searchText })}
                                        />
                                    </ReferenceArrayInput>

                                    <Grid container spacing={2} >
                                        <Grid item xs="auto"  >
                                            <Box sx={{ width: 500, maxWidth: '100%' }}>
                                                <TextInput fullWidth {...rest} source={'module_' + MODULE_IDS.ID_EXIT_INTERVIEW + '.sendgrid_new_exit_interview_template'} />
                                            </Box>
                                        </Grid>
                                        <Grid item xs="auto"  >
                                            <Box sx={{ width: 500, maxWidth: '100%' }}>
                                                <TextInput fullWidth {...rest} source={'module_' + MODULE_IDS.ID_EXIT_INTERVIEW + '.stats_filters_available'} label="resources.clients.fields.stats_filters_available" />
                                            </Box>
                                        </Grid>
                                    </Grid >
                                </div>
                            )
                        )
                    }}
                </FormDataConsumer>
                <Separator />
                <TextInput source={'module_' + MODULE_IDS.ID_TALENT_ACQUISITION + '.whatsapp_sender_id'} />
                <TextInput source={'module_' + MODULE_IDS.ID_TALENT_ACQUISITION + '.waba_key'} />
                <TextInput source={'module_' + MODULE_IDS.ID_TALENT_ACQUISITION + '.whatsapp_template'} />
                <TextInput label="WhatsApp test phone" source={whatsAppPhoneFieldId} />
                <Button variant="outlined" color="secondary" size="small" onClick={sendWhatsAppTest}>Test WhatsApp</Button>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    open={whatsappTestSnackbar.message}
                    autoHideDuration={3000}
                    message={whatsappTestSnackbar.message}
                >
                </Snackbar>
            </SimpleForm>
        </Edit>
    );
}

const Separator = () => <div style={{ height: 5 }} />

export default ClientEdit;
