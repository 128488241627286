import React from 'react'
import {
  ReferenceField,
  TextField,
  Show,
  List,
  ChipField,
  TabbedShowLayout,
  Tab,
  Datagrid,
  Pagination,
  useRecordContext,
  Loading
} from 'react-admin'
import { Grid } from '@mui/material'

const CandidaturesShow = () => {
  return(
      <Show >
        <TabbedShowLayout>
          <Tab label="summary">
            <TextField source="id" />
            <TextField source="name" />
            <TextField source="remote_name" label="Variables" />
            <ReferenceField source="client.id" reference="clients" allowEmpty>
              <TextField source="name" />
            </ReferenceField>
            <TextField source="minimum_percentage_suitable_overwrite" />
            <TextField source="skill_weight_overwrite" />
            <TextField source="profile_weight_overwrite" />
          </Tab >

          <Tab label="skills">
            <ReferenceField source="client.id" reference="clients" allowEmpty>
              <TextField source="name" />
            </ReferenceField>
            <ReferenceField source="skill1.id" reference="skills" label="Skill 1">
              <Grid item xs="auto">
                <ChipField source="name" />
              </Grid>
            </ReferenceField>
            <TextField source="weight_skill1" />
            <ReferenceField source="skill2.id" reference="skills" label="Skill 2">
              <Grid item xs="auto">
                <ChipField source="name" />
              </Grid>
            </ReferenceField>
            <TextField source="weight_skill2" />
            <ReferenceField source="skill3.id" reference="skills" label="Skill 3">
              <Grid item xs="auto">
                <ChipField source="name" />
              </Grid>
            </ReferenceField>
            <TextField source="weight_skill3" />
            <ReferenceField source="skill4.id" reference="skills" label="Skill 4">
              <Grid item xs="auto">
                <ChipField source="name" />
              </Grid>
            </ReferenceField>
            <TextField source="weight_skill4" label="Peso Skill 4" />
          </Tab>

          <Tab label="resources.candidatures.tabs.questions">
            <TabQuestions/>
          </Tab>
        </TabbedShowLayout>
      </Show>
  )
}

const TabQuestions = () => {
  const record = useRecordContext();
  const [page, setPage] = React.useState(1);
  const [perPage, setPerPage] = React.useState(10);

  if (!record || !record.questionsDetails) {
    return <Loading/>;
  }

  const displayedRecords = record.questionsDetails.slice((page - 1) * perPage, page * perPage);

  return (
    <Tab label="resources.candidatures.tabs.questions">
      <List exporter={false} hasCreate={false} pagination={false}>
        <Datagrid
            data={displayedRecords}
            rowClick={(id, basePath, record) => `/questions/${id}/show`}
        >
          <TextField source="id" />
          <TextField source="name" />
          <TextField source="internal_name" />
          <TextField source="weight" />
        </Datagrid>
        <Pagination
            page={page}
            setPage={setPage}
            perPage={perPage}
            setPerPage={setPerPage}
            total={record.questionsDetails.length}
        />
      </List>
    </Tab>
  )
}



export default CandidaturesShow
