import * as React from 'react';
import Button from '@mui/material/Button'
import i18n from '../../assets/i18n'

export default function ExportButton(props) {
  const {
    size = 'small',
    label = i18n.es.ra.action.export,
    onClick = null,
    icon = null,
    exporter= null,
    ...rest
  } = props;

  return (
    <Button
      size={size}
      onClick={onClick}
      label={label}
      {...rest}
    >
      {icon}
      {label}
    </Button>
  );
}
