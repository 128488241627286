import React from 'react'
import { 
  List, 
  Datagrid, 
  TextField, 
  BooleanField, 
  BooleanInput, 
  ReferenceField, 
  ChipField, 
  TextInput 
} from 'react-admin'

const AnswersSkillsFilter = [
  <TextInput source="id" />,
  <TextInput source="name" />,
  <TextInput source="value" />,
  <BooleanInput source="punctuate" />
]

const AnswersSkillsList = () => (
  <List exporter={false} filters={AnswersSkillsFilter}>
    <Datagrid rowClick="show">
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="value" />
      <BooleanField source="punctuate" />
      <ReferenceField source="skill.id" reference="skills" linkType={false}>
        <ChipField source="name" />
      </ReferenceField>
    </Datagrid>
  </List>
)

export default AnswersSkillsList
