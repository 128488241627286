import React from 'react'
import {
    List,
    Datagrid,
    TextField,
    ReferenceField,
    ChipField,
    TextInput,
    CloneButton,
    ReferenceInput,
    AutocompleteInput,
    FunctionField,
    EditButton,
    ShowButton,
    DeleteButton, useListController, Loading
} from 'react-admin'

const CandidaturesFilter = [
    <TextInput source="id" label="ID" />,
    <TextInput source="name" label="Nombre" />,
    <TextInput source="remote_name" label="Nombre interno" />,
    <ReferenceInput
        source="client.id"
        reference="clients"
        allowEmpty
        label="Cliente"
        sort={{ field: 'name', order: 'ASC' }}
        perPage={1000}
    >
        <AutocompleteInput source="name" optionText="name" filterToQuery={(searchText) => ({ name: searchText })} />
    </ReferenceInput>
]

const CandidaturesList = () => {
    const controller = useListController();

    if (controller.isLoading) {
        return <Loading />;
    }

    return (
        <List
            exporter={false}
            filters={CandidaturesFilter}
        >
            <Datagrid rowClick="show">
                <TextField source="id" />
                <TextField source="name" />
                <TextField source="remote_name" label="Variable" />
                <ReferenceField source="client.id" reference="clients" allowEmpty>
                    <TextField source="name" />
                </ReferenceField>
                <ReferenceField source="skill1.id" reference="skills" label="Skill 1">
                    <ChipField source="name" />
                </ReferenceField>
                <ReferenceField source="skill2.id" reference="skills" label="Skill 2">
                    <ChipField source="name" />
                </ReferenceField>
                <ReferenceField source="skill3.id" reference="skills" label="Skill 3">
                    <ChipField source="name" />
                </ReferenceField>
                <ReferenceField source="skill4.id" reference="skills" label="Skill 4">
                    <ChipField source="name" />
                </ReferenceField>
                <FunctionField render={record => {
                    return <CloneButton
                        className="button-clone"
                        record={record}
                    />
                }} />
                <EditButton />
                <ShowButton />
                <DeleteButton />
            </Datagrid>
        </List>
    )
}

export default CandidaturesList
