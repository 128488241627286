import React from 'react';
import {
    Show,
    BooleanField,
    ReferenceField,
    TextField,
    EmailField,
    TabbedShowLayout,
    Tab, useGetOne, Loading, Error,
    ChipField,
    ArrayField,
    SingleFieldList
} from 'react-admin';
import  AvatarField  from '../../../atoms/avatar-field';
import {useParams} from "react-router-dom";


const ClientShow = (props) => {
    const { id } = useParams();
    const { data, loading, error } = useGetOne('users', { id });


    if (loading) return <Loading />;
    if (error) return <Error />;
    return (
        <Show {...props} >
            <TabbedShowLayout>
                <Tab label="summary">
                    <TextField source="id"/>
                    <TextField source="name"/>
                    <TextField source="surname"/>
                    <TextField source="phone"/>
                    <EmailField source="email"/>
                    <ReferenceField source="client.id" reference="clients" allowEmpty>
                        <TextField
                            source="name"
                            optionText="name"
                        />
                    </ReferenceField>
                    <AvatarField record={data} source="avatar" resource="users" />

                    <BooleanField source="technical_selection_user.enabled"/>
                    <TextField source="technical_selection_user.role" />
                    <ArrayField source="user_authorized_sections">
                        <SingleFieldList linkType={false}>
                            <ChipField source="section" translate={"yes"}/>
                        </SingleFieldList>
                    </ArrayField>

                    <BooleanField source="rrhh_user.enabled"/>
                    <TextField source="rrhh_user.role" />

                    <BooleanField source="user_on_boarding.enabled"/>
                    <TextField source="user_on_boarding.role" />

                    <BooleanField source="user_feedback.enabled"/>
                    <TextField source="user_feedback.role" />

                    <BooleanField source="user_exit_interview.enabled"/>
                    <TextField source="user_exit_interview.role" />
                </Tab>
            </TabbedShowLayout>
        </Show>
    )
}


export default ClientShow;