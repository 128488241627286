import * as React from 'react';
import { Box, ThemeProvider, createTheme } from '@mui/system';
import { Grid } from '@mui/material';
import PollIcon from '@mui/icons-material/Poll';

const theme = createTheme({
  palette: {
    background: {
      paper: '#fff',
    },
    text: {
      primary: '#173A5E',
      secondary: '#46505A',
    },
    success: {
      dark: '#009688',
    },
  },
});

export default function DashCard({ label, value, Icon = PollIcon, comparisonNumber = null, comparisonText = 'vs. last month' }) {
  const printPercent = (comparisonNumber) => {
    if (typeof comparisonNumber !== 'number') {
      throw new Error('Se esperaba un número como entrada');
    }
    const porcentaje = (comparisonNumber * 100).toFixed(2);
    const signo = comparisonNumber >= 0 ? '+' : '-';

    return `${signo}${porcentaje}%`;
  }

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          bgcolor: 'background.paper',
          boxShadow: 1,
          borderRadius: 2,
          p: 2,
          maxWidth: 300,
        }}
      >
        <Grid container spacing={2}>
          {Icon !== null && <Grid item xs={3}><Icon sx={{width: 64, height: 64}} /></Grid>}
          <Grid item xs={Icon !== null ? 7 : 12}>
            <Box sx={{ color: 'text.secondary' }}>{ label }</Box>
            <Box sx={{ color: 'text.primary', fontSize: 34, fontWeight: 'medium' }}>{ value }</Box>
            {comparisonNumber !== null && ! isNaN(comparisonNumber) && (
              <>
                <Box
                  sx={{
                    color: 'success.dark',
                    display: 'inline',
                    fontWeight: 'bold',
                    mx: 0.5,
                    fontSize: 14,
                  }}
                >
                  { printPercent(comparisonNumber) }
                </Box>
                <Box sx={{ color: 'text.secondary', display: 'inline', fontSize: 14 }}>{comparisonText}</Box>
              </>
            )}
          </Grid>
        </Grid>
      </Box>
    </ThemeProvider>
  );
}