import { authAdmin } from "../../api";
import { getToken, removeToken } from "../storage/storage";
import { getAuthHeaders } from "../../config/api";

const authProvider = {
    login: async ({ username, password }) => {
        try {
            const data = await authAdmin({ username, password });
            return data;
        } catch (error) {
            throw new Error('Authentication error');
        }
    },
    logout: () => {
        removeToken();
        return Promise.resolve();
    },
    checkAuth: () => {
        return getToken() ? Promise.resolve() : Promise.reject();
    },
    checkError: () => Promise.resolve(),
    getPermissions: () => Promise.resolve(),
    getHeaders: () => getAuthHeaders(),
};

export default authProvider;
