import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import * as api from '../../../api'

const LogoField = ({ record = {}, resource = '' }) => {
    const [image, setImage] = useState(null);
    const [fetchSuccessful, setFetchSuccessful] = useState(false);

    useEffect(() => {
        const fetchImage = async () => {
            try {
                if (fetchSuccessful) return;

                const result = await api.getLogo({ slug: record.id });

                setFetchSuccessful(true);
                setImage(result.url);
            } catch (error) {
                console.error('Error fetching image:', error);
                setImage(null);
            }
        }
        fetchImage();
    }, [record, resource, fetchSuccessful]);

    const onError = () => {
        setImage(null)
    }

    const onClick = e => {
        e.preventDefault()
        window.open(image)
    }

    if (!image) return null;

    return <img style={{ width: 250, cursor: 'pointer' }} src={image} alt="logo" onClick={onClick} onError={onError} />
}


LogoField.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    resource: PropTypes.string
}

LogoField.defaultProps = {
    addLabel: true
}

export default LogoField