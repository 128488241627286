import {
    Show,
    TabbedShowLayout,
    NumberField,
    ReferenceField,
    TextField,
    Tab
} from 'react-admin'
import React from "react";

const NotInterestedTagShow = () => (
    <Show >
        <TabbedShowLayout>
            <Tab label="summary">
                <NumberField source="position" />
                <TextField source="label" />
                <ReferenceField source="client.id" reference="clients" allowEmpty>
                    <TextField source="name" optionText="name"/>
                </ReferenceField>
            </Tab>
        </TabbedShowLayout>
    </Show>
)

export default NotInterestedTagShow
