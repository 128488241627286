import React from 'react'
import { Edit, maxValue, minValue, NumberInput, ReferenceInput, required, SelectInput, SimpleForm, TextInput } from 'react-admin'
import {ColorInput} from "react-admin-color-picker";

const ReminderConfigsEdit = () => {
    return (
        <Edit redirect="show">
            <SimpleForm>
                <ReferenceInput source="client.id" reference="clients" perPage={100} validate={required()}>
                    <SelectInput optionText="name" />
                </ReferenceInput>
                <ReferenceInput source="module_type.id" reference="modules" perPage={100} label="module" validate={required()}>
                    <SelectInput optionText="name" />
                </ReferenceInput>
                <TextInput source="name" validate={required()} />
                <TextInput source="description" validate={required()} />
                <ColorInput source="color" />
                <NumberInput source="order" validate={required()} />
                <NumberInput source="matching_min" validate={[minValue(0), maxValue(100)]} />
                <NumberInput source="matching_max" validate={[minValue(0), maxValue(100)]} />
            </SimpleForm>
        </Edit>
    )
}

export default ReminderConfigsEdit
