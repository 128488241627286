import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import LinearProgressComponent from './linearProgress';
import i18n from "../../../assets/i18n";

function SummaryTable({ data: { summary = [] } = {}, Action }){

    const keys = Object.keys(summary);

    const columns = [
        { field: 'client', headerName: i18n.es.sms_reports.client, bgcolor: 'primary.main' },
        { field: 'candidature_config', headerName: i18n.es.sms_reports.candidature_config, bgcolor: 'primary.main' },
        { field: 'unique_candidates', headerName: i18n.es.sms_reports.unique_candidates, bgcolor: 'primary.main', align: 'right' },
        { field: 'candidates_applied_email', headerName: i18n.es.sms_reports.candidates_applied_email , bgcolor: 'warning.main', align: 'right' },
        { field: 'sent_email', headerName: i18n.es.sms_reports.sent_email, bgcolor: 'warning.light', align: 'right' },
        { field: 'loaded_email', headerName: i18n.es.sms_reports.loaded_email, bgcolor: 'warning.main', align: 'right' },
        { field: 'opened_email', headerName: i18n.es.sms_reports.opened_email, bgcolor: 'warning.light', align: 'right' },
        { field: 'candidates_applied_sms', headerName: i18n.es.sms_reports.candidates_applied_sms, bgcolor: 'error.main', align: 'right' },
        { field: 'sent_sms', headerName: i18n.es.sms_reports.sent_sms, bgcolor: 'error.light', align: 'right' },
        { field: 'loaded_sms', headerName: i18n.es.sms_reports.loaded_sms, bgcolor: 'error.main', align: 'right' },
        { field: 'opened_sms', headerName: i18n.es.sms_reports.opened_sms, bgcolor: 'error.light', align: 'right' },
        { field: 'candidates_applied_whatsapp', headerName: i18n.es.sms_reports.candidates_applied_whatsapp, bgcolor: 'success.main', align: 'right' },
        { field: 'sent_whatsapp', headerName: i18n.es.sms_reports.sent_whatsapp, bgcolor: 'success.light', align: 'right' },
        { field: 'loaded_whatsapp', headerName: i18n.es.sms_reports.loaded_whatsapp, bgcolor: 'success.main', align: 'right' },
        { field: 'opened_whatsapp', headerName: i18n.es.sms_reports.opened_whatsapp, bgcolor: 'success.light', align: 'right' },
        { field: 'loaded', headerName: i18n.es.sms_reports.loaded, bgcolor: 'info.light', align: 'right' },
        { field: 'applied_percentage', headerName: i18n.es.sms_reports.applied_percentage, bgcolor: 'info.main', align: 'right' },
        { field: 'bots_done', headerName: i18n.es.sms_reports.bots_done, bgcolor: 'primary.light', align: 'right' },
    ];

    return (
        <TableContainer component={Paper}>
            <h2>{i18n.es.sms_reports.title.summary}</h2>
            {Action && <Action sx={{ marginBottom: 3, marginLeft: 3 }} columns={columns.map((c) => c.field)} reportName="summary" data={summary} translateKey={i18n.es.sms_reports} />}

            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        {columns.map((column) => (
                            <TableCell
                                key={column.field}
                                align={column.align}
                                sx={{ bgcolor: column.bgcolor, fontWeight: 'bold'}}
                            >
                                {column.headerName}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {keys.length > 0 ? keys.map((key, index) => {
                        const row = summary[key];
                        return (
                            <TableRow key={row.id || index}>
                                <TableCell sx={{ fontWeight: 'bold'}}>{row.client}</TableCell>
                                <TableCell>{row.candidature_config}</TableCell>
                                <TableCell align="right">{row.unique_candidates}</TableCell>
                                <TableCell align="right">{row.candidates_applied_email}</TableCell>
                                <TableCell sx={{fontWeight: 'bold'}} align="right">
                                    <LinearProgressComponent value={row.sent_email} />
                                    {row.sent_email.toFixed(2)}%
                                </TableCell>
                                <TableCell align="right">{row.loaded_email}</TableCell>
                                <TableCell sx={{fontWeight: 'bold'}} align="right">
                                    <LinearProgressComponent value={row.opened_email} />
                                    {row.opened_email.toFixed(2)}%
                                </TableCell>
                                <TableCell align="right">{row.candidates_applied_sms}</TableCell>
                                <TableCell sx={{fontWeight: 'bold'}} align="right">
                                    <LinearProgressComponent value={row.sent_sms} />
                                    {row.sent_sms.toFixed(2)}%
                                </TableCell>
                                <TableCell align="right">{row.loaded_sms}</TableCell>
                                <TableCell sx={{fontWeight: 'bold'}} align="right">
                                    <LinearProgressComponent value={row.opened_sms} />
                                    {row.opened_sms.toFixed(2)}%
                                </TableCell>
                                <TableCell align="right">{row.candidates_applied_whatsapp}</TableCell>
                                <TableCell sx={{fontWeight: 'bold'}} align="right">
                                    <LinearProgressComponent value={row.sent_whatsapp} />
                                    {row.sent_whatsapp.toFixed(2)}%
                                </TableCell>
                                <TableCell align="right">{row.loaded_whatsapp}</TableCell>
                                <TableCell sx={{fontWeight: 'bold'}} align="right">
                                    <LinearProgressComponent value={row.opened_whatsapp} />
                                    {row.opened_whatsapp.toFixed(2)}%
                                </TableCell>
                                <TableCell align="right">{row.loaded}</TableCell>
                                <TableCell sx={{fontWeight: 'bold'}} align="right">
                                    <LinearProgressComponent value={row.applied_percentage} />
                                    {row.applied_percentage.toFixed(2)}%
                                </TableCell>
                                <TableCell align="right">{row.bots_done}</TableCell>
                            </TableRow>
                        );
                    }) : (
                        <TableRow>
                            <TableCell colSpan={23}>No data available</TableCell>
                        </TableRow>
                    )}
                </TableBody>

            </Table>
        </TableContainer>
    );

}



export default SummaryTable;

