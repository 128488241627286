// src/providers/i18nProvider/i18n.js
import polyglotI18nProvider from 'ra-i18n-polyglot';
import englishMessages from 'ra-language-english';
import spanishMessages from 'ra-language-spanish';
import domainMessages from "../../components/assets/i18n";


const customEnglishMessages = {
    ...englishMessages,
    ...domainMessages.en
};

const customSpanishMessages = {
    ...spanishMessages,
    ...domainMessages.es
}


const i18nProvider = polyglotI18nProvider(locale => {
    if (locale === 'es') {
        return customSpanishMessages;
    }
    return customEnglishMessages;
}, 'es');

export default i18nProvider