import React from 'react';
import {
    Edit,
    SimpleForm,
    TextInput,
    NumberInput,
} from 'react-admin';

const FiltersBlockEdit = () => (
    <Edit redirect="show">
        <SimpleForm>
            <TextInput source="name" label="Nombre" />
            <NumberInput source="order" label="Orden" />
        </SimpleForm>
    </Edit>
);

export default FiltersBlockEdit;
