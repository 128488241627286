import React from 'react'
import { Create, SimpleForm, TextInput, ReferenceInput, SelectInput, NumberInput } from 'react-admin'

const AvailabilityTagsCreate = () => (
  <Create redirect="show" >
    <SimpleForm >
      <TextInput source="label" />
      <NumberInput source="position" />
      <ReferenceInput source="client.id" reference="clients" perPage={1000}>
        <SelectInput  optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  </Create>
)

export default AvailabilityTagsCreate
