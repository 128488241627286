import React from 'react'
import {
    Datagrid,
    EmailField,
    Filter,
    List,
    TextField,
    TextInput, EditButton, ShowButton, DeleteButton
} from 'react-admin'

const Filters = props => {
    return <Filter {...props}>
        <TextInput source="id"/>
        <TextInput source="name"/>
        <TextInput source="email"/>
    </Filter>
}

const UsersList = () => {
    return (
        <List exporter={false} filters={<Filters />}>
            <Datagrid rowClick="show">
                <TextField source="id" />
                <TextField source="name" />
                <TextField source="surname" />
                <TextField source="phone" />
                <EmailField source="email" />
                <TextField source="admin_user.role" />

                <EditButton />
                <ShowButton />
                <DeleteButton />
            </Datagrid>
        </List>
    )
}

export default UsersList;