import React from 'react'
import {
    Create,
    SimpleForm,
    TextInput,
    NumberInput
} from 'react-admin'

const FiltersBlockCreate = () => {
    return (
        <Create redirect="show">
            <SimpleForm >
                <TextInput source="name" label="Nombre" />
                <NumberInput source="order" label="Orden" />
            </SimpleForm>
        </Create>
    )
}

export default FiltersBlockCreate
