import React from 'react'
import { Show, TextField, SimpleShowLayout, ReferenceField, RichTextField } from 'react-admin'

const DefaultMessagesShow = () => (
    <Show >
        <SimpleShowLayout>
            <TextField source="id" />
            <TextField source="title" />
            <TextField source="subject" />
            <RichTextField source="body" />
            <TextField source="location" />
            <TextField source="interviewer" />
            <ReferenceField source="client.id" reference="clients" allowEmpty>
                <TextField source="name" />
            </ReferenceField>
            <ReferenceField source="module_type.id" reference="modules" allowEmpty label="module">
                <TextField source="name" />
            </ReferenceField>
        </SimpleShowLayout>
    </Show>
)

export default DefaultMessagesShow
