const ClientFeatures = [
    { id: 'push_offer', name: 'Push offers' },
    { id: 'pull_config', name: 'Pull candidatos' },
    { id: 'doc_request', name: 'Doc request' },
    { id: 'edit_fields', name: 'Campos editables' },
    { id: 'phases_status', name: 'Fases y Estados' },
    { id: 'video_analysis', name: 'Video Analisis' },
    { id: 'vacancy_request', name: 'GPP - Vacancy request' },
]

const Languages = [
    { id: 'es', name: 'Español' },
    { id: 'en', name: 'Ingles' },
    { id: 'pt', name: 'Portugués' },
    { id: 'ca', name: 'Catalán' },
    { id: 'fr', name: 'Francés' },
];

export {
    ClientFeatures,
    Languages,
}
