import * as React from 'react';
import i18n from '../../assets/i18n'
import DownloadIcon from '@mui/icons-material/GetApp'
import ExportButton from '../export-button'
import * as XLSX from 'xlsx'

export default function ExportReportXls({ data, columns, label, translateKey, reportName = '' }) {
  const downloadXLS = (rawData, propColumns) => {
    if(typeof rawData !== 'object') {
      return;
    }

    let data = Object.values(rawData);
    if(!data.length) {
      return;
    }

    const columns = propColumns || Object.keys(data[0]);
    data = data.map(row => {
      let newRow = {};
      columns.map((column) => {
        newRow[translateKey[column] || column] = row[column];
      });
      return newRow;
    });

    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    let date = new Date();
    XLSX.utils.book_append_sheet(wb, ws, "Report");

    if(reportName) {
      reportName += "_";
    }

    XLSX.writeFile(wb, `export_${reportName}${date.getUTCDate()}-${date.getUTCMonth()+1}-${date.getUTCFullYear()}.xlsx`);
  }

  return (
    <ExportButton
      sx={{ marginBottom: 3, marginLeft: 3 }}
      onClick={() => downloadXLS(data, columns)}
      variant="contained"
      label={label}
      disabled={!data || data.length===0}
      icon={<DownloadIcon/>}
    />
  );
}
