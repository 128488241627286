import React from 'react'
import {
  ReferenceField,
  TextField,
  BooleanField,
  ChipField,
  Show,
  TabbedShowLayout,
  Tab,
} from 'react-admin'
import { Grid } from '@mui/material'

const AnswersSkillsShow = () => (
  <Show >
    <TabbedShowLayout>
      <Tab label="summary">
        <TextField source="id" />
        <TextField source="name" />
        <TextField source="value" />
        <BooleanField source="punctuate" />
        <ReferenceField source="skill.id" reference="skills" linkType={false}>
          <Grid item xs="auto">
            <ChipField source="name" />
          </Grid>
        </ReferenceField>
     
        <ReferenceField source="axis1.id" reference="axis" linkType={false} label="Axis 1">
          <Grid item xs="auto">
            <ChipField source="name" />
          </Grid>
        </ReferenceField>
        <ReferenceField source="mbti_value1.id" reference="types/mbtis" linkType={false} >
          <Grid item xs="auto">
            <ChipField source="value" />
          </Grid>
        </ReferenceField>

        <ReferenceField source="axis2.id" reference="axis" linkType={false} label="Axis 2">
          <Grid item xs="auto">
            <ChipField source="name" />
          </Grid>
        </ReferenceField>
        <ReferenceField source="mbti_value2.id" reference="types/mbtis" linkType={false} >
          <Grid item xs="auto">
            <ChipField source="value" />
          </Grid>
        </ReferenceField>

        <ReferenceField source="axis3.id" reference="axis" linkType={false} label="Axis 3" >
          <Grid item xs="auto">
            <ChipField source="name" />
          </Grid>
        </ReferenceField>
        <ReferenceField source="mbti_value3.id" reference="types/mbtis" linkType={false} >
          <Grid item xs="auto">
            <ChipField source="value" />
          </Grid>
        </ReferenceField>

        <ReferenceField source="axis4.id" reference="axis" linkType={false} label="Axis 4">
          <Grid item xs="auto"  >
            <ChipField source="name" />
          </Grid>
        </ReferenceField>
        <ReferenceField source="mbti_value4.id" reference="types/mbtis" fields="mbti_value_1" linkType={false}>
          <Grid item xs="auto"  >
            <ChipField source="value" />
          </Grid>
        </ReferenceField>
      </Tab>
    </TabbedShowLayout>
  </Show>
)

export default AnswersSkillsShow
