import React from 'react'
import { 
  Create, 
  SimpleForm, 
  TextInput, 
  NumberInput, 
  required
} from 'react-admin'

const AnswersProfilesCreate = () => (
  <Create redirect={"show"}>
    <SimpleForm >
      <TextInput source="name" validate={[required()]} />
      <TextInput source="value" validate={[required()]} />
      <NumberInput source="score" validate={[required()]} />
    </SimpleForm>
  </Create>
)

export default AnswersProfilesCreate
