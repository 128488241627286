import React from 'react'
import {
    List,
    Datagrid,
    ReferenceField,
    TextField,
    TextInput,
    ReferenceInput,
    SelectInput,
    AutocompleteInput, EditButton, ShowButton, DeleteButton
} from 'react-admin'

const ReportFieldsFilter = [
    <TextInput source="id" />,
    <TextInput source="name" />,
    <ReferenceInput source="client.id"
        reference="clients"
        allowEmpty
        label="Cliente"
        sort={{ field: 'name', order: 'ASC' }}
        perPage={1000}
    >
        <AutocompleteInput
            source="name"
            optionText="name"
            filterToQuery={(searchText) => ({ name: searchText })}
            suggestionLimit={10}
        />
    </ReferenceInput>,
    <ReferenceInput source="module_type.id" reference="modules" allowEmpty label="module">
        <SelectInput optionText="name" />
    </ReferenceInput>,
    <TextInput source="remote_name" />,
]

const ReportFieldsList = () => (
    <List filters={ReportFieldsFilter}>
        <Datagrid rowClick="show">
            <TextField source="id" />
            <TextField source="name" />
            <TextField source="remote_name" />
            <ReferenceField source="client.id" reference="clients" allowEmpty>
                <TextField source="name" />
            </ReferenceField>
            <ReferenceField source="module_type.id" reference="modules" allowEmpty label="module">
                <TextField source="name" />
            </ReferenceField>
            <EditButton />
            <ShowButton />
            <DeleteButton />
        </Datagrid>
    </List>
)

export default ReportFieldsList
