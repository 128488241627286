const UserRoles = [
    { id: 'BASIC', name: 'Básico' },
    { id: 'SUPERVISOR', name: 'Supervisor' },
    { id: 'ATS_ADMIN', name: 'Administrador de ATS' },
    { id: 'READ_ONLY', name: 'Solo lectura' }
]
const UserAuthorizedSections = [
    {id: 'inbox', name: 'Inbox'},
    {id: 'push_offer', name: 'Ofertas Push'},
    {id: 'pull_config', name: 'Configuración Pull'},
    {id: 'saved_searches', name: 'Búsquedas guardadas'},
    {id: 'planning', name: 'Planificación'},
    {id: 'favorites', name: 'Favoritos'},
    {id: 'reports', name: 'Informes'},
    {id: 'statistics_dashboard', name: 'Dashboard estadísticas'},
    {id: 'rpa_config', name: 'Fuentes de reclutamiento'},
    {id: 'evaluative_tests', name: 'Video entrevistas'},
    {id: 'template_requestor', name: 'GPP - Creador de peticiones'},
    {id: 'template_validator', name: 'GPP - Validador de peticiones'},
]

export {UserRoles, UserAuthorizedSections}
