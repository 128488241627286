import React from 'react'
import PropTypes from 'prop-types'
import {List, Datagrid, TextField, Filter, TextInput, SelectInput, ReferenceInput, DeleteButton} from 'react-admin'
import ImportButton from './importButton'

const Actions = ({ resource, filters, showFilter, displayedFilters, filterValues }) => (
    <React.Fragment>
        {filters ?
            React.cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button',
            })
            : null
        }
        <ImportButton />
    </React.Fragment>
);


const ClientsFilter = (props) => (
    <Filter {...props}>
        <TextInput source="client.id" />
        <TextInput source="client.name" />
        <TextInput source="dni" />
        <ReferenceInput source="module_type.id" reference="modules" allowEmpty label="module">
            <SelectInput optionText="name" />
        </ReferenceInput>
    </Filter>
)

const ClientsList = (props) => {
    return (
        <List {...props} exporter={false} filters={<ClientsFilter />} actions={<Actions />}>
            <Datagrid rowClick="show">
                <TextField source="client.id" />
                <TextField source="client.name" />
                <TextField source="module_type.name" label="module" />
                <TextField source="dni" />
                <DeleteButton />
            </Datagrid>
        </List>
    )
}

Actions.propTypes = {
    resource: PropTypes.object.isRequired,
    filters: PropTypes.object.isRequired,
    showFilter: PropTypes.func.isRequired,
    displayedFilters: PropTypes.object.isRequired,
    filterValues: PropTypes.node.isRequired,
    refreshView: PropTypes.func.isRequired,
}

Actions.defaultProps = {}

export default ClientsList
