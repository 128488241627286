import React from 'react'
import {Show, TextField, SimpleShowLayout, ReferenceField} from 'react-admin'

const InboxesShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="remote_name" />
      <ReferenceField source="module_type.id" reference="modules" allowEmpty label="module">
        <TextField source="name" />
      </ReferenceField>
    </SimpleShowLayout>
  </Show>
)

export default InboxesShow
