import React from 'react'
import {
  Datagrid, List, Loading, Pagination,
  ReferenceField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  useRecordContext
} from 'react-admin'

const CandidaturesShow = () => (
  <Show >
    <TabbedShowLayout>
      <Tab label="summary">
        <TextField source="name"/>
        <TextField source="remote_name"/>
        <TextField source="prefix"/>

        <ReferenceField source="client.id" reference="clients">
          <TextField source="name" />
        </ReferenceField>
      </Tab>

      <Tab label="questions">
        <TabQuestions/>
      </Tab>
    </TabbedShowLayout>
  </Show>
)

const TabQuestions = () => {
  const record = useRecordContext();
  const [page, setPage] = React.useState(1);
  const [perPage, setPerPage] = React.useState(10);

  if (!record || !record.questionsDetails) {
    return <Loading/>;
  }

  const displayedRecords = record.questionsDetails.slice((page - 1) * perPage, page * perPage);

  return (
      <Tab label="resources.suggestion_feedback_configs.tabs.questions">
        <List exporter={false} hasCreate={false} pagination={false}>
          <Datagrid
              data={displayedRecords}
              rowClick={(id, basePath, record) => `/questions/${id}/show`}
          >
            <TextField source="id" />
            <TextField source="name" />
            <TextField source="internal_name" />
            <TextField source="weight" />
          </Datagrid>
          <Pagination
              page={page}
              setPage={setPage}
              perPage={perPage}
              setPerPage={setPerPage}
              total={record.questionsDetails.length}
          />
        </List>
      </Tab>
  )
}



export default CandidaturesShow
