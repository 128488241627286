import React, { Fragment } from 'react'
import {
    Create,
    TextInput,
    ReferenceInput,
    SelectInput,
    SimpleForm,
    NumberInput, FormDataConsumer, AutocompleteInput
} from "react-admin";
import { FILTER_TYPES_CHOICES } from "../../../../config/api";
const FiltersCreate = () => {
    return (
        <Create redirect="show">
            <SimpleForm >
                <TextInput source="name" />
                <TextInput source="remote_name" />
                <ReferenceInput
                    source="module_type.id"
                    reference="modules"
                    perPage={100}
                    label="module">
                    <SelectInput optionText="name" />
                </ReferenceInput>
                <FormDataConsumer>
                    {({ formData, ...rest }) => (
                        <Fragment>
                            <ReferenceInput
                                source="client.id"
                                reference="clients"
                                allowEmpty
                                label="Cliente"
                                sort={{ field: 'name', order: 'ASC' }}
                                perPage={1000}
                            >
                                <AutocompleteInput optionText="name"  filterToQuery={(searchText) => ({ name: searchText })} />
                            </ReferenceInput>

                            <ReferenceInput
                                source='candidature.id'
                                reference="candidatures"
                                label="Candidatura"
                                filter={{ 'client.id': formData.client?.id }}
                                perPage={1000}
                            >
                                <SelectInput optionText="name" />
                            </ReferenceInput>
                        </Fragment>
                    )}
                </FormDataConsumer>
                <ReferenceInput
                    source="filter_block.id"
                    reference="filter_block"
                    allowEmpty
                    label="Bloque de Filtro"
                    perPage={1000}
                >
                    <SelectInput optionText="name" />
                </ReferenceInput>
                <SelectInput source="type" choices={FILTER_TYPES_CHOICES} />
                <NumberInput source="order" />
            </SimpleForm>
        </Create>
    )
}

export default FiltersCreate

