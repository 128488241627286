import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { format, parse } from 'date-fns'
import * as api from '../../../api'
import * as S from './styled'
import { useRecordContext } from 'react-admin';
import {Delete} from "@mui/icons-material";

const DeleteUsersField = ({ moduleId, moduleName }) => {
    const recordContext = useRecordContext();
    const [fromDate, setFromDate] = useState('')
    const [toDate, setToDate] = useState('')
    const [state, setState] = useState([])
    const [stateLabel, setStateLabel] = useState([])

    const [isConfirm, setIsConfirm] = useState(false)
    const [isOpen, setIsOpen] = useState(false)

    const deleteUsers = async () => {
        try {
            const id = recordContext.id
            const formattedFromDate = parse(fromDate, 'yyyy-MM-dd', new Date())
            const formattedFromDateResult = format(formattedFromDate, "yyyy-MM-dd'T'HH:mm:ss")
            const formattedToDate = parse(toDate, 'yyyy-MM-dd', new Date())
            const formattedToDateResult = format(formattedToDate, "yyyy-MM-dd'T'HH:mm:ss")
            return await api.removeUsersByClient(id, moduleId, formattedFromDateResult, formattedToDateResult, state)
        } catch (error) {
            console.error(error)
        }
    }

    const onDelete = (e) => {
        e.preventDefault()
        setIsConfirm(true)
    }

    const onConfirm = () => {
        deleteUsers()
    }

    return (
        <>
            {isOpen && (
                <S.ModalWrapper>
                    <S.Modal>
                        <h3>{`${recordContext.name} / ${moduleName}`}</h3>
                        <S.DatesHolder>
                            <input type="date" value={fromDate} onChange={(e) => setFromDate(e.target.value)}></input>
                            <input type="date" value={toDate} onChange={(e) => setToDate(e.target.value)}></input>
                        </S.DatesHolder>

                        {moduleId === 'talent_acquisition' && (
                            <select
                                style={{ width: '100%', marginBottom: '10px' }}
                                multiple
                                onChange={(e) => {
                                    const values = Array.from(e.target.selectedOptions, (option) => option.value)
                                    const labels = Array.from(e.target.selectedOptions, (option) => option.text)
                                    setState(values)
                                    setStateLabel(labels)
                                }}
                            >
                                <option value={1}>Apto</option>
                                <option value={2}>No apto</option>
                                <option value={3}>Por confirmar</option>
                                <option value={4}>Confirmado</option>
                                <option value={5}>No interesado</option>
                                <option value={6}>Reprogramar</option>
                                <option value={7}>Contratado</option>
                                <option value={8}>Reserva</option>
                                <option value={9}>No asiste</option>
                                <option value={10}>Citado fase 2</option>
                                <option value={11}>Descartado</option>
                                <option value={12}>Descartado fase 2</option>
                                <option value={13}>No interesado fase 2</option>
                                <option value={14}>No asiste fase 2</option>
                            </select>
                        )}

                        {moduleId === 'employee_line' && (
                            <select
                                style={{ width: '100%', marginBottom: '10px' }}
                                multiple
                                onChange={(e) => {
                                    const values = Array.from(e.target.selectedOptions, (option) => option.value)
                                    const labels = Array.from(e.target.selectedOptions, (option) => option.text)
                                    setState(values)
                                    setStateLabel(labels)
                                }}
                            >
                                <option value="received">Recibido</option>
                                <option value="in_process">En proceso</option>
                                <option value="processed">Tramitado</option>
                                <option value="pending_info">Pendiente de info</option>
                                <option value="canceled">Cancelado</option>
                            </select>
                        )}

                        {isConfirm && fromDate && toDate ? (
                            <div style={{ textAlign: 'center', color: 'red' }}>
                                <span>Vas a eliminar datos del cliente </span>
                                <span>
                  <strong>{recordContext.name}</strong>
                </span>
                                {moduleId !== 'on_board' && stateLabel.length > 0 && (
                                    <>
                                        <span> relacionados con: </span>
                                        <span>
                      <strong>{stateLabel.join(', ')}</strong>
                    </span>
                                    </>
                                )}
                                <br />
                                <br />
                                <span> ¿Estás seguro?</span>
                                <S.ButtonsHolder>
                                    <S.Button href="#" onClick={onConfirm}>
                                        Confirmar
                                    </S.Button>
                                    <S.Button href="#" onClick={() => setIsOpen(false)}>
                                        Cancelar
                                    </S.Button>
                                </S.ButtonsHolder>
                            </div>
                        ) : (
                            <div style={{ textAlign: 'center', color: 'green' }}>
                                <span>Introduce un rango de fechas {(moduleId === 'employee_line' || moduleId === 'talent_acquisition') && 'y, al menos, un estado'}</span>
                                <S.ButtonsHolder>
                                    <S.Button href="#" onClick={onDelete}>
                                        Borrar
                                    </S.Button>
                                    <S.Button href="#" onClick={() => setIsOpen(false)}>
                                        Cerrar
                                    </S.Button>
                                </S.ButtonsHolder>
                            </div>
                        )}
                    </S.Modal>
                </S.ModalWrapper>
            )}
            <Delete style={{ cursor: 'pointer' }} onClick={() => setIsOpen(!isOpen)} />
        </>
    )
}

DeleteUsersField.propTypes = {
    record: PropTypes.object,
    moduleId: PropTypes.string,
    moduleName: PropTypes.string,
}

export default DeleteUsersField
