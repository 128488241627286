import React from 'react';
import {
    Show,
    TextField,
    EmailField,
    TabbedShowLayout,
    Tab, useGetOne, Loading, Error
} from 'react-admin';
import {useParams} from "react-router-dom";


const ClientShow = (props) => {
    const { id } = useParams();
    const { data, loading, error } = useGetOne('admin_users', { id });


    if (loading) return <Loading />;
    if (error) return <Error />;
    return (
        <Show {...props} >
            <TabbedShowLayout>
                <Tab label="summary">
                    <TextField source="id"/>
                    <TextField source="name"/>
                    <TextField source="surname"/>
                    <TextField source="phone"/>
                    <EmailField source="email"/>
                    <TextField source="admin_user.role" />
                </Tab>
            </TabbedShowLayout>
        </Show>
    )
}

export default ClientShow;