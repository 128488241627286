import React from 'react'
import {Create, SimpleForm, TextInput, required, SelectInput, ReferenceInput} from 'react-admin'

const InboxesCreate = () => (
  <Create redirect="show">
    <SimpleForm >
        <TextInput source="name" validate={[required()]} />
        <TextInput source="remote_name" validate={[required()]} />
        <ReferenceInput
            source="module_type.id"
            reference="modules"
            perPage={100}
            label="module">
          <SelectInput optionText="name" />
        </ReferenceInput>
    </SimpleForm>
  </Create>
)

export default InboxesCreate
