import React from 'react'
import { Edit, SimpleForm, TextInput, ReferenceInput, SelectInput, AutocompleteInput } from 'react-admin'
import { RichTextInput } from "ra-input-rich-text";

const DefaultMessagesEdit = () => (
    <Edit redirect={"show"}>
        <SimpleForm redirect="show">
            <TextInput source="title" />
            <TextInput source="subject" />
            <RichTextInput toolbar={null} source="body" />
            <TextInput source="location" />
            <TextInput source="interviewer" />
            <ReferenceInput
                source="client.id"
                reference="clients"
                sort={{ field: 'name', order: 'ASC' }}
                perPage={1000}
            >
                <AutocompleteInput source="name" optionText="name" filterToQuery={(searchText) => ({ name: searchText })} />
            </ReferenceInput>
            <ReferenceInput source="module_type.id" reference="modules" perPage={100} allowEmpty label="module">
                <SelectInput optionText="name" />
            </ReferenceInput>
        </SimpleForm>
    </Edit>
)

export default DefaultMessagesEdit
