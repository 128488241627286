import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    Filter,
    TextInput, ShowButton, EditButton, DeleteButton,
} from 'react-admin'

const ClientsFiltersBlockFilter = props => (
    <Filter {...props}>
        <TextInput source="id" />
        <TextInput source="name" label="Nombre" />
    </Filter>
);

const FiltersBlockList=()=> (
    <List filters={<ClientsFiltersBlockFilter />}>
        <Datagrid rowClick="show">
            <TextField source="id" />
            <TextField source="name" label="Nombre"/>
            <TextField source="order" label="Orden" />
            <EditButton/>
            <ShowButton />
            <DeleteButton />
        </Datagrid>
    </List>
);

export default FiltersBlockList;
