import React from 'react'
import {
    List,
    Datagrid,
    ReferenceField,
    TextField,
    Filter,
    TextInput,
    ReferenceInput,
    SelectInput,
    AutocompleteInput, EditButton, ShowButton, DeleteButton
} from 'react-admin'

const ClientsFilter = props => (
    <Filter {...props}>
        <TextInput source="id" />
        <TextInput source="name" />
        <ReferenceInput
            source="client.id"
            reference="clients"
            label="Cliente"
            sort={{ field: 'name', order: 'ASC' }}
            perPage={1000}
        >
            <AutocompleteInput
                source="name"
                optionText="name"
                filterToQuery={(searchText) => ({ name: searchText })}
                suggestionLimit={10}
            />
        </ReferenceInput>
        <ReferenceInput
            source='candidature.id'
            reference="candidatures"
            allowEmpty
            label="Candidatura"
            perPage={1000}
        >
            <SelectInput source="name" optionText="name" />
        </ReferenceInput>
        <ReferenceInput
            source="module_type.id"
            reference="modules"
            label="Modulo">

            <SelectInput  optionText="name" />
        </ReferenceInput>
        <ReferenceInput
            source='filters_block.id'
            reference="filter_block"
            perPage={100}
        >
            <SelectInput optionText="name" />
        </ReferenceInput>
        <TextInput source="remote_name" />
    </Filter>
)

const FiltersList = () => (
    <List  exporter={false} filters={<ClientsFilter />}>
        <Datagrid rowClick="show">
            <TextField source="id" />
            <TextField source="name" />
            <TextField source="remote_name" />
            <TextField source="type" />
            <ReferenceField source="client.id" reference="clients" allowEmpty>
                <TextField source="name" optionText="name"/>
            </ReferenceField>
            <ReferenceField source="candidature.id" reference="candidatures" allowEmpty label="Candidatura">
                <TextField source="name" optionText="name"/>
            </ReferenceField>
            <ReferenceField source="filter_block.id" reference="filter_block" allowEmpty >
                <TextField source="name" optionText="name" />
            </ReferenceField>
            <ReferenceField source="module_type.id" reference="modules" allowEmpty label="module">
                <TextField source="name" optionText="name" />
            </ReferenceField>
            <EditButton/>
            <ShowButton />
            <DeleteButton />
        </Datagrid>
    </List>
)

export default FiltersList
