import React from 'react'
import {List, Datagrid, TextField, NumberField, ReferenceField, EditButton, ShowButton, DeleteButton} from 'react-admin'

const AvailabilityTagsList = () => (
  <List exporter={false}>
    <Datagrid rowClick="show">
      <TextField source="id" />
      <TextField source="label" />
      <ReferenceField source="client.id" reference="clients">
        <TextField source="name" />
      </ReferenceField>
      <NumberField source="position" />
        <EditButton />
        <ShowButton />
        <DeleteButton />
    </Datagrid>
  </List>
)

export default AvailabilityTagsList