import React from 'react'
import { ReferenceField, Show, Tab, TabbedShowLayout, TextField } from 'react-admin'
import {ColorField} from "react-admin-color-picker";

const ReminderConfigsShow = () => {
    return (
        <Show>
            <TabbedShowLayout>
                <Tab label="summary">
                    <TextField source="id" />
                    <ReferenceField source="client.id" reference="clients" allowEmpty>
                        <TextField source="name" />
                    </ReferenceField>
                    <ReferenceField source="module_type.id" reference="modules" allowEmpty>
                        <TextField source="name" />
                    </ReferenceField>
                    <TextField source="name" />
                    <TextField source="description" />
                    <ColorField source="color" />
                    <TextField source="order" />
                </Tab>
            </TabbedShowLayout>
        </Show>
    )
}

export default ReminderConfigsShow
