import React from 'react'
import { Edit, SimpleForm, TextInput, ReferenceInput, SelectInput, NumberInput, AutocompleteInput } from 'react-admin'

const NotInterestedTagsEdit = () => (
    <Edit redirect="show">
        <SimpleForm >
            <TextInput source="label" />
            <NumberInput source="position" />
            <ReferenceInput
                source="client.id"
                reference="clients"
                sort={{ field: 'name', order: 'ASC' }}
                perPage={1000}
            >
                <AutocompleteInput
                    source="name"
                    optionText="name"
                    filterToQuery={(searchText) => ({ name: searchText })}
                />
            </ReferenceInput>
        </SimpleForm>
    </Edit>
)

export default NotInterestedTagsEdit
