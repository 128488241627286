import { getToken } from './../providers/storage/storage'
export const BASE_URL = process.env.REACT_APP_API_BASE_URL
export const API_ENTRYPOINT = process.env.REACT_APP_API_ENTRYPOINT
export const API_PROFILE_HARD_ID = process.env.REACT_APP_API_PROFILE_HARD_ID
export const GERO_API_BASE_URL = process.env.REACT_APP_GERO_API_BASE_URL

export const getAuthHeaders = () => {
    const token = getToken();
    return {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
    };
};

export const roles = {
    ROLE_ADMIN: 'ROLE_ADMIN',
    ROLE_USER: 'ROLE_USER'
}

export const MODULE_IDS = {
    ID_TALENT_ACQUISITION: 'talent_acquisition',
    ID_ON_BOARDING: 'on_board',
    ID_WELCOME_PACK: 'welcome',
    ID_EMPLOYEE_LINE: 'employee_line',
    ID_FEEDBACK: 'feedback',
    ID_ABSENTEEISM: 'abseentism',
    ID_SURVEYS: 'survey',
    ID_VISUAL_TUTOR: 'visual',
    ID_EXIT_INTERVIEW: 'exit_interview',
}

export const globalParameters = {
    filter: '{}',
    range: '[0,9]',
    sort: '["id","DESC"]'
};

export const PROCESS_TYPES = {
    ID_CONSULTIVO: 'consultivo',
    ID_TRANSACCIONAL: 'transaccional'
}

export const FILTER_TYPES = {
    text: 'text',
    select: 'select',
    range_date: 'range_date',
    range_number: 'range_number',
}

export const FILTER_TYPES_CHOICES = Object.values(FILTER_TYPES).map((item) => ({id: item, name: item}))
