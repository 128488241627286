import * as React from 'react';
import DashCard from '../../../atoms/dash-card';
import i18n from "../../../assets/i18n";
import { Box, Stack } from '@mui/system';
import {
  Chip,
  Grid,
  List,
  ListItem,
  ListItemText,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import { Download, Map, Sms, Upload, WhatsApp } from '@mui/icons-material';

export default function StatisticPanel({ data: statistics }) {
  const cardKeyIcon = (key) => {
    const mappedIcons = {
      'google:distance_matrix_api': Map,
      'here maps:public_transit_api': Map
    }
    return mappedIcons[key] || null;
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Box>{ i18n.es.client_statistics.enabled_services }</Box>
          <Stack spacing={{ xs: 1, sm: 2 }} direction="row" useFlexGap flexWrap="wrap">
            {(statistics?.whatsapp?.available_feature ?? false) && <Chip label={i18n.es.client_statistics.whatsapp} icon={<WhatsApp />} />}
            {(statistics?.sms?.available_feature ?? false) && <Chip label={i18n.es.client_statistics.sms} icon={<Sms />} />}
            {(statistics?.push_offers?.available_feature ?? false) && <Chip label={i18n.es.client_statistics.push_offers} icon={<Upload />} />}
            {(statistics?.pull_offers?.available_feature ?? false) && <Chip label={i18n.es.client_statistics.pull_offers} icon={<Download />} />}
          </Stack>
        </Grid>

        {! isNaN(statistics.candidates) && (
          <Grid item xs={4}>
            <DashCard label={i18n.es.client_statistics.candidates} value={statistics.candidates} />
          </Grid>
        )}

        {(Object.keys(statistics.api_requests)).map((service) => {
          return (
            <>
              <Grid item xs={4}>
                <DashCard
                  label={i18n.es.client_statistics.api_request_services[service] || service}
                  value={statistics.api_requests[service]}
                  Icon={cardKeyIcon(service)}
                />
              </Grid>
            </>
          );
        })}
      </Grid>

      <TableContainer sx={{mt: 4}} component={Paper}>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell colSpan={3}>{i18n.es.client_statistics.whatsapp}</TableCell>
              <TableCell colSpan={2}>{i18n.es.client_statistics.sms}</TableCell>
              <TableCell colSpan={2}>{i18n.es.client_statistics.push_offers}</TableCell>
              <TableCell colSpan={2}>{i18n.es.client_statistics.pull_offers}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{i18n.es.client_statistics.whatsapp_voucher}</TableCell>
              <TableCell>{i18n.es.client_statistics.whatsapp_spent}</TableCell>
              <TableCell>{i18n.es.client_statistics.whatsapp_details}</TableCell>
              <TableCell>{i18n.es.client_statistics.sms_sent}</TableCell>
              <TableCell>{i18n.es.client_statistics.sms_details}</TableCell>
              <TableCell>{i18n.es.client_statistics.push_offer_number}</TableCell>
              <TableCell>{i18n.es.client_statistics.push_notified_candidates}</TableCell>
              <TableCell>{i18n.es.client_statistics.pull_notified_candidates}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>{ statistics.whatsapp?.voucher ?? 0 }</TableCell>
              <TableCell>{ statistics.whatsapp?.voucher_spent ?? 0 }</TableCell>
              <TableCell>
                <List dense={true} sx={{p: 0, m: 0}}>
                  {(Object.keys(statistics.whatsapp?.by_types ?? {})).map((type) => {
                    return (
                      <>
                        <ListItem sx={{p: 0, m: 0}}>
                          <ListItemText sx={{p: 0, m: 0}} primary={type + ': ' + statistics.whatsapp.by_types[type]} />
                        </ListItem>
                      </>
                    );
                  })}
                </List>
              </TableCell>
              <TableCell>{ statistics.sms?.total_sent ?? 0 }</TableCell>
              <TableCell>
                <List dense={true} sx={{p: 0, m: 0}}>
                  {(Object.keys(statistics.sms?.by_types ?? {})).map((type) => {
                    return (
                      <>
                        <ListItem sx={{p: 0, m: 0}}>
                          <ListItemText sx={{p: 0, m: 0}} primary={type + ': ' + statistics.sms.by_types[type]} />
                        </ListItem>
                      </>
                    );
                  })}
                </List>
              </TableCell>
              <TableCell>{statistics.push_offers?.push_offers ?? 0}</TableCell>
              <TableCell>{statistics.push_offers?.notified_candidates ?? 0}</TableCell>
              <TableCell>{ statistics.pull_offers?.notified_candidates ?? 0 }</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
