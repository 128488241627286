import React, {Fragment} from "react";
import {
    SimpleForm,
    DateInput,
    SaveButton,
    useNotify,
    ReferenceArrayInput,
    AutocompleteArrayInput,
    Loading,
} from 'react-admin';
import { format } from 'date-fns';
import {getSmsReportsFetchData} from "../../../../api";
import SummaryTable from "./summaryTable";
import EmailTable from "./emailTable";
import SmsTable from "./smsTable";
import WhatsappTable from "./whatsappTable";
import Box from "@mui/material/Box";
import ExportReportXls from '../../../molecules/export-report-xls'

const SmsReportsList = () => {
    const notify = useNotify();
    let yesterday = new Date();
    yesterday.setDate(yesterday.getDate() -1);
    const [loading, setLoading] = React.useState(false);
    const hasSubmittedRef = React.useRef(false);
    const yesterdayString = `${yesterday.getFullYear()}-${String(yesterday.getMonth() + 1).padStart(2, '0')}-${String(yesterday.getDate()).padStart(2, '0')}`;
    const [inputValue, setInputValue] = React.useState(null);
    const [from, setFrom] = React.useState(yesterdayString);
    const [to, setTo] = React.useState(yesterdayString);
    const [reportData, setReportData] = React.useState([]);

    function formatDate(date) {
        return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
    }

    const handleSubmit = async () => {
        setLoading(true);
        let clientsArray = [];

        if (Array.isArray(inputValue)) {
            clientsArray = inputValue.map(value => ({
                id: value,
            }));
        }

        const output = {
            from,
            to,
            clients: clientsArray,
        };

        try {
            await getSmsReportsFetchData(output.from, output.to, output.clients).then((res) => {
                setReportData(res.json);
                notify('ra.notification.success_operation', { type: 'success' });
            });
        } catch (err) {
            console.error('Error fetching SMS reports:', err);
            notify('ra.notification.bad_operation', { type: 'error' });
        } finally {
            setLoading(false);
        }
    };

    React.useEffect(() => {
        if (from && to && !hasSubmittedRef.current) {
            handleSubmit().then(() => {});
            hasSubmittedRef.current = true;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [from, to]);

    return (
        <Fragment>
            {loading ? (
                <Loading />
                ) : (
                <>
                <SimpleForm toolbar={null} onSubmit={handleSubmit}>
            <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}>
                <DateInput
                    source="from"
                    label="Fecha de inicio"
                    value={from}
                    defaultValue={from}
                    inputProps={{
                        min: '2020-01-01',
                        max: to ? to : yesterdayString,
                    }}
                    onChange={(e) => setFrom(e.target.value)}
                />
                <DateInput
                    source="to"
                    label="Fecha de fin"
                    value={to}
                    defaultValue={to}
                    inputProps={{
                        min: from ? from : '2020-01-01',
                        max: yesterdayString,
                    }}
                    onChange={(e) => setTo(e.target.value)}
                />
                <ReferenceArrayInput
                    reference="clients"
                    source="clients"
                    perPage={1000}
                    sort={{ field: 'name', order: 'ASC' }}
                >
                    <AutocompleteArrayInput
                        optionText="name"
                        filterToQuery={(searchText) => ({ name: searchText })}
                        // shouldRenderSuggestions={(val) => { return val.trim().length > 0 }}
                        onChange={(newValue) => {
                            setInputValue(newValue);
                        }}
                    />
                </ReferenceArrayInput>

                <SaveButton
                    sx={{
                        margin: '8px 16px 26px 16px',
                        width: 'auto',
                        padding: '0px 16px 0px 16px',
                        '& .MuiSvgIcon-root': {
                            marginTop: '-2px',
                        }
                    }}
                />
            </div>
        </SimpleForm>

    <Box mb={4}>
        <SummaryTable data={reportData} Action={(props) => <ExportReportXls {...props} />}/>
    </Box>
    <Box mb={4}>
        <EmailTable data={reportData} Action={(props) => <ExportReportXls {...props} />}/>
    </Box>
    <Box mb={4}>
        <SmsTable data={reportData} Action={(props) => <ExportReportXls {...props} />}/>
    </Box>
    <Box mb={4}>
        <WhatsappTable data={reportData} Action={(props) => <ExportReportXls {...props} />}/>
    </Box>
</>
)}
</Fragment>
);

};


export default SmsReportsList;
