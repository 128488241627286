import React from 'react'
import {Create, NumberInput, ReferenceInput, required, SelectInput, SimpleForm, TextInput, AutocompleteInput, minValue, maxValue} from 'react-admin'
import {ColorInput} from "react-admin-color-picker";

const ReminderConfigsCreate = () => {
    return (
        <Create redirect="show">
            <SimpleForm >
                <ReferenceInput
                    source="client.id"
                    reference="clients"
                    allowEmpty
                    label="Cliente"
                    sort={{field: 'name', order: 'ASC'}}
                    perPage={1000}
                >
                    <AutocompleteInput source="name" optionText="name" filterToQuery={(searchText) => ({ name: searchText })}/>
                </ReferenceInput>
                <ReferenceInput
                    source="module_type.id"
                    reference="modules"
                    perPage={100}
                    label="module"
                    validate={required()}>
                    <SelectInput optionText="name" />
                </ReferenceInput>
                <TextInput source="name" validate={required()} />
                <TextInput source="description" validate={required()} />
                <ColorInput source="color" />
                <NumberInput source="order" validate={required()} />
                <NumberInput source="matching_min" validate={[minValue(0), maxValue(100)]} />
                <NumberInput source="matching_max" validate={[minValue(0), maxValue(100)]} />
            </SimpleForm>
        </Create>
    )
}

export default ReminderConfigsCreate
