import React from 'react'
import {
    List,
    Datagrid,
    TextField,
    BooleanField,
    ReferenceField,
    TextInput,
    ReferenceInput,
    SelectInput,
    BooleanInput
} from 'react-admin'

const QuestionsFilter = [
    <TextInput source="id" />,
    <TextInput source="name" />,
    <ReferenceInput source="module_type.id" reference="modules" allowEmpty label="module">
        <SelectInput optionText="name" />
    </ReferenceInput>,
    <TextInput source="internal_name" />,
    <TextInput source="remote_name" />,
    <BooleanInput source="variable_field" />,
    <BooleanInput source="is_editable_by_client" />,
    <BooleanInput source="is_gpp_showable" />,
    <TextInput source="weight" />,
]

const QuestionsList = () => (
    <List exporter={false} filters={QuestionsFilter}>
        <Datagrid rowClick="show">
            <TextField source="id" />
            <TextField source="name" />
            <TextField source="internal_name" />
            <TextField source="remote_name" />
            <ReferenceField source="profile.id" reference="profiles" linkType={false}>
                <TextField source="name" />
            </ReferenceField>
            <BooleanField source="variable_field" />
            <BooleanField source="is_editable_by_client" />
            <BooleanField source="is_gpp_showable" />
            <ReferenceField source="module_type.id" reference="modules" allowEmpty label="module">
                <TextField source="name" />
            </ReferenceField>
            <TextField source="weight" />
            <TextField source="order" />
        </Datagrid>
    </List>
)

export default QuestionsList
