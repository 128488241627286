import React from 'react'
import { 
  Show, 
  SimpleShowLayout, 
  TextField, 
  NumberField 
} from 'react-admin'

const AnswersProfilesShow = () => (
  <Show >
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="value" />
      <NumberField source="score" />
    </SimpleShowLayout>
  </Show>
)

export default AnswersProfilesShow
