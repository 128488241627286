import { Edit, SimpleForm, required, ReferenceArrayInput, AutocompleteArrayInput, AutocompleteInput } from 'react-admin'
import { TextInput, ReferenceInput } from 'react-admin'
import { MODULE_IDS } from "../../../../config/api";
import Box from '@mui/material/Box';

const ProcessEdit = () => {
  return (
    <Edit undoable={false} redirect="show" >
      <SimpleForm >
        <TextInput source="name" validate={[required()]} />
        <TextInput source="remote_name" validate={[required()]} />
        <Box sx={{ width: 230, maxWidth: '100%' }}>
          <ReferenceInput
            source="client.id"
            reference="clients"
            allowEmpty
            label="Cliente"
            sort={{ field: 'name', order: 'ASC' }}
            perPage={1000}
          >
            <AutocompleteInput source="name" optionText="name" filterToQuery={(searchText) => ({ name: searchText })} />
          </ReferenceInput>
        </Box>

        <ReferenceArrayInput
          reference="questions"
          source="questions"
          filter={{ "moduleType.id": MODULE_IDS.ID_EXIT_INTERVIEW }}
          perPage={1000}
          filterToQuery={searchText => ({ internal_name: searchText, "moduleType.id": MODULE_IDS.ID_EXIT_INTERVIEW })}
          allowEmpty
        >
          <AutocompleteArrayInput optionText="internal_name" shouldRenderSuggestions={() => true} inputValueMatcher={() => true} />
        </ReferenceArrayInput>
      </SimpleForm>
    </Edit>
  )
}

export default ProcessEdit
