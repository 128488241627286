import { fetchJson } from "../fetchJson";
import { getToken } from "../../storage/storage";

export default (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' })
    }
    const token = getToken()
    options.headers.set('Authorization', `Bearer ${token}`)
    return fetchJson(url, options)
}