import React from 'react'
import {
    List,
    Datagrid,
    TextField,
    ReferenceField,
    Filter,
    TextInput,
    SelectInput,
    ReferenceInput,
    EditButton, ShowButton, DeleteButton, AutocompleteInput
} from 'react-admin'

const OnboardingConfigsFilter = props => (
  <Filter {...props}>
    <TextInput source="id" />
    <TextInput source="name" />
      <ReferenceInput
          source="client.id"
          reference="clients"
          label="Cliente"
          sort={{ field: 'name', order: 'ASC' }}
          perPage={1000}
      >
          <AutocompleteInput source="name" optionText="name" filterToQuery={(searchText) => ({ name: searchText })} />
      </ReferenceInput>
    <TextInput source="type.name" />
    <ReferenceInput source="type.id" reference="processtypes">
      <SelectInput optionText="name" />
    </ReferenceInput>
  </Filter>
)

const OnboardingConfigsList = () => (
  <List exporter={false} filters={<OnboardingConfigsFilter />}>
    <Datagrid rowClick="show">
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="remote_name" />
      <ReferenceField source="client.id" reference="clients" allowEmpty>
        <TextField source="name" />
      </ReferenceField>
      <EditButton />
      <ShowButton />
      <DeleteButton />
    </Datagrid>
  </List>
)

export default OnboardingConfigsList
