import React, { Fragment } from 'react';
import Dialog from '@mui/material/Dialog';
import PublishIcon from '@mui/icons-material/Publish';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import {useNotify, useRefresh} from 'react-admin';
import { SimpleForm, FileInput, FileField, ReferenceInput, SelectInput, required } from 'react-admin';
import { importBlacklist } from '../../../../api';

const ImportButton = () => {
    const [isOpen, setIsOpen] = React.useState(false);
    const notify = useNotify();
    const refresh = useRefresh();


      const handleOpen = () => {
        setIsOpen(true);
      };

      const handleClose = () => {
        setIsOpen(false);
        refresh();
      };

      const handleConfirm = async (form) => {
            try {
                const { clientId, files, module_type } = form;
                    const file = await fileToBase64(files.rawFile);

                    await importBlacklist({ moduleId: module_type.id, slug: clientId, file });
                notify('Operación realizada con exito', { type: 'success' });
                handleClose();
            } catch (err) {
                notify('Operación fallida', { type: 'error' });
            }
      };

      function fileToBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);

            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
      }

    return (
      <>
          <Button
              onClick={handleOpen}
              startIcon={
                  <PublishIcon
                  />
              }
          >
              <span style={{ marginTop: '4px' }}>Importar</span>
          </Button>

          <Dialog open={isOpen} onClose={handleClose}>
          <DialogTitle>Importar lista negra</DialogTitle>
          <SimpleForm onSubmit={handleConfirm} >
            <ReferenceInput source="clientId" reference="clients" validate={required()}>
              <SelectInput  sx={{
                  width: '100%',
                  fontSize: '1.2em',
              }}
                optionText="name" />
            </ReferenceInput>
            <ReferenceInput source="module_type.id" reference="modules" validate={required()}>
              <SelectInput  sx={{
                  width: '100%',
                  fontSize: '1.2em',
              }}
                optionText="name" />
            </ReferenceInput>
            <FileInput source="files" accept=".csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel">
              <FileField source="src" title="title" />
            </FileInput>
          </SimpleForm>
        </Dialog>
      </>
  );
};

export default ImportButton;