import * as React from 'react';
import i18n from "../../../assets/i18n";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';

export default function DailyCandidatesByClient({ data: statistics }) {
  const dinamicDayNameColumns = statistics?.column_titles ? Object.values(statistics?.column_titles) : []
  const dinamicDayKeyColumns = statistics?.column_titles ? Object.keys(statistics?.column_titles) : []
  const avgCounter = statistics.avg_counter
  const from = statistics.from
  const to = statistics.to
  const clientStatisticRows = statistics?.rows ? Object.values(statistics?.rows) : []

  return (
    <>
      <TableContainer sx={{mt: 4}} component={Paper}>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell colSpan={avgCounter + 3} align='center'><strong>Candidatos/Día ({from} al {to})</strong></TableCell>
            </TableRow>
            <TableRow>
              <TableCell></TableCell>
              {dinamicDayNameColumns.map(function (text) {
                return (<TableCell><strong>{text}</strong></TableCell>)
              })}
              <TableCell><strong>Average</strong></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {clientStatisticRows.map(function (clientStats) {
              let total = 0
              return (
                <TableRow>
                  <TableCell>{ clientStats?.client_name ?? '-' }</TableCell>
                  {dinamicDayKeyColumns.map(function (dayKey) {
                    let amount = dayKey in clientStats.column_data ? clientStats.column_data[dayKey] : 0

                    total += amount

                    return (<TableCell>{amount}</TableCell>)
                  })}
                  <TableCell>{ Math.floor(total / avgCounter) }</TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}