import React from 'react'
import { 
  List,
  Datagrid, 
  TextField, 
  NumberField, 
  TextInput 
} from 'react-admin'

const AnswersSkillsFilter = [
    <TextInput source="id" />,
    <TextInput source="name" />,
    <TextInput source="value" />
 ]

const AnswersProfilesList = () => (
  <List exporter={false} filters={AnswersSkillsFilter}>
    <Datagrid rowClick="show">
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="value" />
      <NumberField source="score" />
    </Datagrid>
  </List>
)

export default AnswersProfilesList
