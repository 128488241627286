import {Edit, SimpleForm, required, FormDataConsumer, ReferenceArrayInput, AutocompleteArrayInput} from 'react-admin'
import { TextInput, ReferenceInput, SelectInput } from 'react-admin'
import {MODULE_IDS, PROCESS_TYPES} from "../../../../config/api";

const ProcessEdit = () => {
  return (
    <Edit redirect="show" undoable={false}>
      <SimpleForm >
        <TextInput source="name" validate={[required()]} />
        <TextInput source="remote_name" validate={[required()]} />
        <TextInput source="prefix" inputProps={{ maxLength: 3 }} validate={[required()]} />

        <ReferenceInput source="client.id" reference="clients" perPage={1000}>
          <SelectInput optionText="name" />
        </ReferenceInput>

        <ReferenceInput source="type.id" reference="processtypes" label="processType">
          <SelectInput optionText="name" />
        </ReferenceInput>

        <FormDataConsumer>
          {// eslint-disable-next-line no-unused-vars
            ({ formData, dispatch, ...rest }) => (
                formData.type && formData.type.id === PROCESS_TYPES.ID_TRANSACCIONAL &&
                <ReferenceArrayInput
                    {...rest}
                    reference="questions"
                    source="questions"
                    filter={{"moduleType.id": MODULE_IDS.ID_EMPLOYEE_LINE}}
                    perPage={1000}
                    filterToQuery={searchText => ({ internal_name: searchText, "moduleType.id": MODULE_IDS.ID_EMPLOYEE_LINE  })}
                    allowEmpty
                >
                  <AutocompleteArrayInput optionText="internal_name" shouldRenderSuggestions={() => true} inputValueMatcher={() => true} />
                </ReferenceArrayInput>
            )}
        </FormDataConsumer>


      </SimpleForm>
    </Edit>
  )
}

export default ProcessEdit
