import React from 'react'
import { ReferenceInput, SelectInput, required, FormDataConsumer } from 'react-admin'
import PropTypes from 'prop-types'

const AxisReferenceInput = ({ source, field }) => (
  <FormDataConsumer>
  {({ dispatch }) => {
    return (
      <ReferenceInput
        source={source}
        resource={source}
        reference="axis"
        perPage={100}
        validate={[required()]}
        onChange={() => dispatch(field, null)}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
    );
  }}
</FormDataConsumer>
)

AxisReferenceInput.propTypes = {
  field: PropTypes.string,
  source: PropTypes.string
}

export default AxisReferenceInput