import React, { useState } from 'react'
import {
  AutocompleteArrayInput,
  Create,
  FormDataConsumer,
  ReferenceArrayInput,
  ReferenceInput,
  required,
  SimpleForm,
  TextInput,
  AutocompleteInput,
} from 'react-admin'
import { MODULE_IDS } from "../../../../config/api";
import Box from '@mui/material/Box';

const ProcessesCreate = () => {
  const [count, setCount] = useState(0)
  return (
    <Create redirect="show" >
      <SimpleForm >
        <TextInput source="name" validate={[required()]} />
        <TextInput source="remote_name" validate={[required()]} />
        <Box sx={{ width: 230, maxWidth: '100%' }}>
          <ReferenceInput
            source="client.id"
            reference="clients"
            allowEmpty
            label="Cliente"
            sort={{ field: 'name', order: 'ASC' }}
            perPage={1000}
          >
            <AutocompleteInput source="name" optionText="name" filterToQuery={(searchText) => ({ name: searchText })} />
          </ReferenceInput>
        </Box>
        <FormDataConsumer>
          {// eslint-disable-next-line no-unused-vars
            ({ formData, dispatch, ...rest }) => (
              <ReferenceArrayInput
                {...rest}
                key={`questions_${count}`}
                reference="questions"
                source="questions"
                filter={{ "moduleType.id": MODULE_IDS.ID_EXIT_INTERVIEW }}
                perPage={1000}
                filterToQuery={searchText => ({ internal_name: searchText, "moduleType.id": MODULE_IDS.ID_EXIT_INTERVIEW })}
                onChange={() => setTimeout(() => setCount(count + 1), 250)}
                allowEmpty
              >
                <AutocompleteArrayInput optionText="internal_name" />
              </ReferenceArrayInput>
            )}
        </FormDataConsumer>
      </SimpleForm>
    </Create>
  )
}

export default ProcessesCreate
