import React from 'react'
import {
    Datagrid,
    Filter,
    List,
    ReferenceField,
    TextField,
    TextInput,
    ReferenceInput,
    SelectInput,
    AutocompleteInput, EditButton, ShowButton, DeleteButton
} from 'react-admin'
import {ColorField} from "react-admin-color-picker";


const Filters = (props) => (
    <Filter {...props}>
        <TextInput source="id" />
        <ReferenceInput
            source="client.id"
            reference="clients"
            allowEmpty
            label="Cliente"
            sort={{ field: 'name', order: 'ASC' }}
            perPage={1000}
        >
            <AutocompleteInput source="name" optionText="name" filterToQuery={(searchText) => ({ name: searchText })} />
        </ReferenceInput>
        <ReferenceInput source="module_type.id" reference="modules" allowEmpty label="module">
            <SelectInput optionText="name" />
        </ReferenceInput>
    </Filter>
)

const ReminderConfigsList = () => (
    <List filters={<Filters />}>
        <Datagrid rowClick="show">
            <TextField source="id" />
            <ReferenceField source="client.id" reference="clients" allowEmpty>
                <TextField source="name" />
            </ReferenceField>
            <ReferenceField source="module_type.id" reference="modules" allowEmpty>
                <TextField source="name" />
            </ReferenceField>
            <TextField source="name" />
            <TextField source="order" />
            <ColorField source="color" />
            <EditButton />
            <ShowButton />
            <DeleteButton />
        </Datagrid>
    </List>
)

export default ReminderConfigsList
