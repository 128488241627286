import React from 'react'
import {Create, SimpleForm, TextInput, ReferenceInput, SelectInput, NumberInput, AutocompleteInput} from 'react-admin'

const ReportFieldsCreate = () => {
    return (
        <Create redirect="show">
            <SimpleForm >
                <TextInput source="name" />
                <TextInput source="remote_name" />
                <ReferenceInput
                    source="client.id" reference="clients"
                    allowEmpty
                    label="Cliente"
                    sort={{field: 'name', order: 'ASC'}}
                    perPage={1000}
                >
                    <AutocompleteInput source="name" optionText="name" filterToQuery={(searchText) => ({ name: searchText })}/>
                </ReferenceInput>
                <ReferenceInput source="module_type.id" reference="modules" perPage={100} allowEmpty label="module">
                    <SelectInput optionText="name" />
                </ReferenceInput>
                <NumberInput source="order" />
            </SimpleForm>
        </Create>
    )
}

export default ReportFieldsCreate
