import React, {Fragment} from "react";
import {
    SimpleForm,
    DateInput,
    SaveButton,
    useNotify,
    ReferenceInput,
    Loading,
    AutocompleteInput
} from 'react-admin';
import { format } from 'date-fns';
import { fetchCsStatisticsPanel } from "../../../../api";
import Box from "@mui/material/Box";
import ModuleConfig from "../index";
import MonthlyCandidatesByClient from "../metrics/monthly_candidates_by_client";
import DailyCandidatesByClient from "../metrics/daily_candidates_by_client";
import { maxWidth } from "@mui/system";

const CustomerSuccessPanel = () => {
    const ModuleIcon = ModuleConfig.icon;
    const notify = useNotify();
    
    const firstDayOfMonth = new Date().setDate(1);
    const today = new Date();

    const isFullfilledForm = React.useRef(false);
    const [loading, setLoading] = React.useState(false);
    const [client, setClient] = React.useState(null);
    const [from, setFrom] = React.useState(format(firstDayOfMonth, 'yyyy-MM-dd'));
    const [to, setTo] = React.useState(format(today, 'yyyy-MM-dd'));
    const [statisticsResponse, setStatisticsResponse] = React.useState(null);

    const handleSetFrom = (event) => {
        const value = event.target.value;
        setFrom(value);
    }

    const handleSetTo = (event) => {
        const value = event.target.value;
        setTo(value);
    }

    const handleSetClient = (client) => {
        console.log('debug client', client)
        setClient(client);
    }

    const handleSubmit = async () => {
        setLoading(true);

        fetchCsStatisticsPanel(from, to, client)
            .then((res) => {
                setStatisticsResponse(res.json);
                notify('ra.notification.success_operation', { type: 'success' });
                setLoading(false);
            })
            .catch((error) => {
                notify('ra.notification.bad_operation', { type: 'error' });
                setLoading(false);
            });
    };

    React.useEffect(() => {
        if (from && to && client && ! isFullfilledForm.current) {
            isFullfilledForm.current = true;
        }
    }, [from, to, client]);

    return (
        <Fragment>
            {loading ? (<Loading />) : (
                <>
                    <SimpleForm toolbar={null} onSubmit={handleSubmit}>
                        <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}>
                            <SaveButton
                                sx={{
                                    margin: '8px 16px 26px 16px',
                                    width: 'auto',
                                    padding: '0px 16px 0px 16px',
                                    '& .MuiSvgIcon-root': {
                                        marginTop: '-2px',
                                    }
                                }}
                                icon={<ModuleIcon />}
                                label="Consultar"
                            />
                            <DateInput
                                source="from"
                                label="Fecha de inicio"
                                value={from}
                                defaultValue={from}
                                inputProps={{
                                    min: '2020-01-01',
                                    max: to ? to : firstDayOfMonth,
                                }}
                                onChange={handleSetFrom}
                            />
                            <DateInput
                                source="to"
                                label="Fecha de fin"
                                value={to}
                                defaultValue={to}
                                inputProps={{
                                    min: from ? from : '2020-01-01',
                                    max: today,
                                }}
                                onChange={handleSetTo}
                            />
                            <ReferenceInput
                                reference="clients"
                                source="clients"
                                perPage={1000}
                                sort={{ field: 'name', order: 'ASC' }}
                                queryOptions={{ cacheTime: 300000, staleTime: 300000 }}
                            >
                                <AutocompleteInput
                                    defaultValue={client}
                                    optionText="name"
                                    filterToQuery={(searchText) => ({ name: searchText })}
                                    onChange={handleSetClient}
                                    multiple={true}
                                    style={{maxWidth: '50%'}}
                                />
                            </ReferenceInput>
                        </div>
                    </SimpleForm>

                    { statisticsResponse?.monthly_candidates_by_client &&
                        <Box mb={4}>
                            <MonthlyCandidatesByClient data={statisticsResponse.monthly_candidates_by_client} />
                        </Box>
                    }

                    { statisticsResponse?.daily_candidates_by_client &&
                        <Box mb={4}>
                            <DailyCandidatesByClient data={statisticsResponse.daily_candidates_by_client} />
                        </Box>
                    }
                </>
            )}
        </Fragment>
    );
};

export default CustomerSuccessPanel;
