import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  DateInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  Filter,
} from 'react-admin';

const RpaReportsFilter = (props) => {
   return (
    <Filter {...props} >
      <DateInput
        alwaysOn
        source="date"        
        required
      />
      <ReferenceArrayInput
        alwaysOn
        reference="clients"
        source="client_id"
        perPage={1000}
        sort={{ field: 'name', order: 'ASC' }}
      >
        <AutocompleteArrayInput 
        optionText="name" 
        filterToQuery={(searchText) => ({ name: searchText })}        
        />
      </ReferenceArrayInput>
    </Filter>
  );
};

const RpaReports = (props) => {
  return (
    <List exporter={false} filters={<RpaReportsFilter />} {...props}>
      <Datagrid >
        <TextField source="id" />
        <TextField source="session" />
        <TextField source="client_name" />
        <TextField source="goal_value" />
        <TextField source="current_value" />

      </Datagrid>
    </List>
  )
};

export default RpaReports;

