import React from 'react';
import {
    Show,
    TabbedShowLayout,
    Tab,
    TextField,
} from 'react-admin';

const FiltersBlockShow = props => (
    <Show {...props}>
        <TabbedShowLayout>
            <Tab label="summary">
                <TextField source="id" />
                <TextField source="name" label="Nombre"/>
                <TextField source="order" label="Orden" />
            </Tab>
        </TabbedShowLayout>
    </Show>
);

export default FiltersBlockShow;
