import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import i18n from "../../../assets/i18n";


function EmailTable({ data: { email = [] } = {}, Action }) {
    const uniqueDates = [...new Set(email.map(item => item.date))];
    const clients = [...new Set(email.map(item => item.client))];

    const totals = uniqueDates.map(date => {
        return email.filter(item => item.date === date).reduce((acc, curr) => acc + curr.sent, 0);
    });

    const grandTotal = totals.reduce((acc, curr) => acc + curr, 0);

    const groupedData = {};
    email.forEach(item => {
        if(!groupedData[item.client]) {
            groupedData[item.client] = {
                client: item.client,
                total: 0
            };
        }

        groupedData[item.client][item.date] = item.sent;
        groupedData[item.client].total += (item.sent || 0);
    });

    return (
        <TableContainer component={Paper}>
            <h2>{i18n.es.sms_reports.title.email}</h2>
            {Action && <Action sx={{ marginBottom: 3, marginLeft: 3 }} reportName="email" data={Object.values(groupedData)} translateKey={i18n.es.sms_reports}/>}

            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        <TableCell style={{ fontWeight: 'bold' }}>{i18n.es.sms_reports.client}</TableCell>
                        {uniqueDates.map((date, index) => (
                            <TableCell style={{ fontWeight: 'bold' }} key={index} align="right">{date}</TableCell>
                        ))}
                        <TableCell  style={{ fontWeight: 'bold' }} align="right">{i18n.es.sms_reports.total}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {clients.length > 0 ? (
                        clients.map((client, index) => (
                            <TableRow key={index}>
                                <TableCell component="th" scope="row" style={{ fontWeight: 'bold', backgroundColor: '#f9f9f9' }}>
                                    {client}
                                </TableCell>
                                {uniqueDates.map((date) => {
                                    const row = email.find(item => item.date === date && item.client === client);
                                    return (
                                        <TableCell key={date} align="right" style={{ backgroundColor: '#f9f9f9' }}>
                                            {row ? row.sent : ""}
                                        </TableCell>
                                    );
                                })}
                                <TableCell align="right" style={{ backgroundColor: '#f9f9f9' }}>
                                    {email.filter(item => item.client === client).reduce((acc, curr) => acc + curr.sent, 0)}
                                </TableCell>
                            </TableRow>
                        ))
                    ) : (
                        <TableRow>
                            <TableCell colSpan={uniqueDates.length + 2}>{i18n.es.sms_reports.No_data_available}</TableCell>
                        </TableRow>
                    )}
                    <TableRow>
                        <TableCell style={{ fontWeight: 'bold' }}>{i18n.es.sms_reports.total}</TableCell>
                        {totals.map((total, index) => (
                            <TableCell key={index} align="right">{total}</TableCell>
                        ))}
                        <TableCell align="right">{grandTotal}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default EmailTable;




