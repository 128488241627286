import React from 'react'
import {
  Create,
  SimpleForm,
  TextInput,
  BooleanInput,
  ReferenceInput,
  required,
  AutocompleteInput
} from 'react-admin'
import { Grid } from '@mui/material'
import { AxisReferenceInput, MbtisReferenceInput } from '../../../atoms'

const AnswersSkillsCreate = () => (
  <Create redirect={"show"}>
    <SimpleForm >
      <Grid container spacing={2} >
        <Grid item xs="auto"  >
          <TextInput source="name" validate={[required()]} />
        </Grid>
        <Grid item xs="auto"  >
          <TextInput source="value" validate={[required()]} />
        </Grid>
        <Grid item xs="auto"  >
          <BooleanInput source="punctuate" />
        </Grid>
      </Grid>
      <ReferenceInput
        source="skill.id"
        reference="skills"
        sort={{ field: 'name', order: 'ASC' }}
        perPage={1000}
      >
        <AutocompleteInput fullWidth
          source="name"
          optionText="name"
          filterToQuery={(searchText) => ({ name: searchText })}
        />
      </ReferenceInput>

      <Grid container spacing={2} >
        <Grid item xs="auto"  >
          <AxisReferenceInput source="axis1.id" field="mbti_value_1" label="Axis 1"/>
        </Grid>
        <Grid item xs="auto"  >
          <MbtisReferenceInput source="mbti_value_1.id" field="axis1" />
        </Grid>
        <Grid item xs="auto"  >
          <AxisReferenceInput source="axis2.id" field="mbti_value_2" label="Axis 2"/>
        </Grid>
        <Grid item xs="auto"  >
          <MbtisReferenceInput source="mbti_value_2.id" field="axis2"  />
        </Grid>
        <Grid item xs="auto"  >
          <AxisReferenceInput source="axis3.id" field="mbti_value_3" label="Axis 3" />
        </Grid>
        <Grid item xs="auto"  >
          <MbtisReferenceInput source="mbti_value_3.id" field="axis3" />
        </Grid>
        <Grid item xs="auto"  >
          <AxisReferenceInput source="axis4.id" field="mbti_value_4"  label="Axis 4"/>
        </Grid>
        <Grid item xs="auto"  >
          <MbtisReferenceInput source="mbti_value_4.id" field="axis4" />
        </Grid>
      </Grid>
    </SimpleForm>
  </Create>
)

export default AnswersSkillsCreate
