import show from './show'
import list from './list'
import create from './create'
import edit from './edit'
import icon from '@mui/icons-material/Done'

const adminConfig = { list, create, edit, show, icon };

export default adminConfig;

